import { useState } from "react";
import { Modal } from "react-bootstrap";
import { setDraftData } from "../../Store/Slices/draftMailSlice";
import { useDispatch } from "react-redux";

export const SelectTemplateModal = ({
    showTemplateModal,
    setShowTemplateModal,
    workflowList,
    setSelectedTemplate
}) => {
    let [searchValue, setSearchValue] = useState('');
    let [showPreviewModal, setShowPreviewModal] = useState(false);
    let [selectedTemplateData, setSelectedTemplateData] = useState('');

    return (
        <>
            <Modal
                show={showTemplateModal}
                onHide={() => { setShowTemplateModal(false) }}
                backdrop="static"
                keyboard={false}
                className="modal-xl"
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title fs-5" id="emailTemplateFormLabel">
                        Select Template
                    </h5>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '400px' }}>
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white"
                                        value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="col-md-12">
                                <div className="tbl-container">
                                    <div className="table-responsive">
                                        <table className="table table-hover hover-icon-table users-table mb-0">
                                            <tbody>
                                                {workflowList?.templateData?.length > 0 &&
                                                    <>
                                                        {/* {workflowList?.templateData?.map((data) => ( */}
                                                        {/* {workflowList?.templateData?.map((data) => ( */}
                                                        {workflowList?.templateData?.filter((item) => {
                                                            if (!searchValue) return true
                                                            if (item?.rules?.emailData?.templateData?.templateName.includes(searchValue)) {
                                                                return true
                                                            }
                                                        }).map((data) => (
                                                            <tr>
                                                                <td className="fw-semibold">
                                                                    <i className="fa-regular fa-star me-2 fs-10"></i>
                                                                    <span className="cursor-pointer"
                                                                        onClick={() => {
                                                                            setSelectedTemplate(data?.rules?.emailData);
                                                                            setShowTemplateModal(false);
                                                                        }}
                                                                    >{data?.rules?.emailData?.templateData?.templateName}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="hover-icon" style={{ color: '#338cf0' }}
                                                                        onClick={() => { setSelectedTemplateData(data.rules.emailData.templateData); setShowPreviewModal(true); }}
                                                                    >Preview</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PreviewModal
                showPreviewModal={showPreviewModal}
                setShowPreviewModal={setShowPreviewModal}
                selectedTemplateData={selectedTemplateData}
                setSelectedTemplateData={() => setSelectedTemplateData('')}
            />
        </>
    )
}

export const PreviewModal = ({
    showPreviewModal,
    setShowPreviewModal,
    selectedTemplateData,
    setSelectedTemplateData
}) => {
    return (
        <Modal
            show={showPreviewModal}
            onHide={() => { setShowPreviewModal(false); setSelectedTemplateData('') }}
            backdrop="static"
            keyboard={false}
            className="modal-xl"
        >
            <Modal.Header closeButton>
                <div className="row">
                    <span className="col-12 fw-semibold">{selectedTemplateData?.templateName}</span>
                    <span className="col-12">{selectedTemplateData?.subject}</span>
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '500px', backgroundColor: 'rgb(240,240,240)' }}>
                <div className="row align-items-center mb-3">
                    <div dangerouslySetInnerHTML={{ __html: selectedTemplateData?.content }} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const ScheduleModal = ({
    showScheduleModal,
    setShowScheduleModal,
    handleSchedule
}) => {
    let [schedule_at, setSchedulAt] = useState();
    let [date, setDate] = useState();
    let [time, setTime] = useState();

    const getNextHour = () => {
        const date = new Date();
        date.setHours(date.getHours() + 1);
        date.setSeconds(0, 0);
        return date.toISOString();
    };

    const getTomorrow8PM = () => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(20, 0, 0, 0);
        return date.toISOString();
    };

    const createISODateTime = (year, month, day, hour, minute, second = 0) => {
        const date = new Date(year, month - 1, day, hour, minute, second);
        return date.toISOString();
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const now = new Date();

        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date >= today && date < tomorrow) {
            return `at ${formatTime(date)}, Today`;
        }
        else if (date >= tomorrow && date < new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate() + 1)) {
            return `at ${formatTime(date)}, Tomorrow`;
        }
        else {
            return `on ${new Intl.DateTimeFormat("en-US", {
                weekday: "short",
                month: "short",
                day: "2-digit"
            }).format(date)} at ${formatTime(date)}`;
        }
    };

    const formatTime = (date) => {
        return new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        }).format(date);
    };

    const handleScheduleMail = (type) => {
        let schedule_dt;
        if (schedule_at === 'one_hour') {
            schedule_dt = getNextHour();
        }
        if (schedule_at === 'tomorrow_8pm') {
            schedule_dt = getTomorrow8PM()
        }
        if (schedule_at === 'custom_datetime') {
            let _year = new Date(date).getUTCFullYear()
            let _month = new Date(date).getUTCMonth() + 1
            let _date = new Date(date).getUTCDate()
            let _hour = time.split(':')[0]
            let _min = time.split(':')[1]
            schedule_dt = createISODateTime(_year, _month, _date, _hour, _min)
        }
        let payload = {
            schedule_at: schedule_dt,
            schedule_day: formatDate(schedule_dt),
            type: type
        }
        handleSchedule(payload)
    }

    return (
        <Modal
            show={showScheduleModal}
            onHide={() => { setShowScheduleModal(false) }}
            backdrop="static"
            keyboard={false}
            className="modal-md"
            centered
        >
            <Modal.Header closeButton>
                <h5 className="modal-title fs-5" id="emailTemplateFormLabel">
                    Schedule at
                </h5>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '150px' }}>
                {/* will show in future */}
                {/* <div className="form-control border-0">
                    <div>
                        <input className="me-1" type="radio" value="matching_contact" name="contactType" />
                        Best time to email
                    </div>
                    <span>Not available for this record</span>
                </div> */}
                <div className="form-control border-0">
                    <div>
                        <input className="me-1" type="radio" value="one_hour" name="schedule_at"
                            checked={schedule_at === 'one_hour'} onChange={(e) => setSchedulAt(e?.target?.value)} />
                        One hour from now
                    </div>
                </div>
                <div className="form-control border-0">
                    <div>
                        <input className="me-1" type="radio" value="tomorrow_8pm" name="schedule_at"
                            checked={schedule_at === 'tomorrow_8pm'} onChange={(e) => setSchedulAt(e?.target?.value)} />
                        Tomorrow evening 8 PM
                    </div>
                </div>
                <div className="form-control border-0">
                    <div>
                        <input className="me-1" type="radio" value="custom_datetime" name="schedule_at"
                            checked={schedule_at === 'custom_datetime'} onChange={(e) => setSchedulAt(e?.target?.value)} />
                        <span>
                            Date
                            <input type="date" name="date" className="form-control w-auto d-inline ms-2 me-2"
                                value={date} onChange={(e) => setDate(e?.target?.value)} />
                            Time
                            <input type="time" name="time" className="form-control w-auto d-inline ms-2"
                                value={time} onChange={(e) => setTime(e?.target?.value)} />
                        </span>
                    </div>
                </div>
                {/* will show in future */}
                {/* <div className="form-control border-0">
                    <div>
                        <select className='form-select' id='time_format' name="Time_Format">
                            <option value="(GMT 5:30) India Standard Time (Asia/Kolkata)">(GMT 5:30) India Standard Time (Asia/Kolkata)</option>
                            <option value="(GMT 5:30) India Standard Time (IST)">(GMT 5:30) India Standard Time (IST)</option>
                        </select>
                    </div>
                </div> */}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                    // onClick={() => { setShowScheduleModal(false) }}
                    onClick={() => handleScheduleMail('schedule_close')}
                >Schedule & Close</button>
                <button type="button" className="btn btn-primary rounded-pill btn-blue"
                    // onClick={() => { setShowScheduleModal(false) }}
                    onClick={() => handleScheduleMail('schedule')}
                >Schedule</button>
            </Modal.Footer>
        </Modal>
    )
}

export const DraftWarningModal = ({
    showDraftWarningModal,
    setShowDraftWarningModal,
    currentDraftIndex,
    removeCurrentDraftData
}) => {
    const dispatch = useDispatch();

    // const removeCurrentDraftData = () => {
    //     let sessionData = JSON.parse(sessionStorage.getItem('draftDetails')) || []
    //     if (sessionData.length > 0) {
    //         let updatedArray = sessionData.filter((_, i) => i !== currentDraftIndex);
    //         sessionStorage.setItem("draftDetails", JSON.stringify(updatedArray));
    //         let allDrafts = sessionStorage.getItem('draftDetails')
    //         dispatch(setDraftData(allDrafts))
    //     }
    //     setShowDraftWarningModal(false);
    // }

    return (
        <Modal
            show={showDraftWarningModal}
            onHide={() => setShowDraftWarningModal(false)}
            backdrop="static"
            keyboard={false}
            className="modal-md"
        >
            <Modal.Body>
                <p className='fw-semibold'>
                    Are you sure you want to permanently delete the Draft?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-outline-secondary rounded-pill flat-btn text-secondary"
                    onClick={() => setShowDraftWarningModal(false)}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-danger rounded-pill btn-blue"
                    // onClick={() => { setShowDraftWarningModal(false); sessionStorage.removeItem('draftDetails') }}
                    // onClick={() => { removeCurrentDraftData() }}
                    onClick={() => { removeCurrentDraftData(); setShowDraftWarningModal(false) }}
                >
                    Yes, Delete
                </button>
            </Modal.Footer>
        </Modal>
    )
}