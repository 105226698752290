import axios from "axios";
import { getItem } from './localService';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const IPIFY_URL = 'https://api.ipify.org?format=json';
const IPINFO_URL = 'https://ipinfo.io';
const IPINFO_TOKEN = 'cd098328187f98';

const URL = {
    LOGIN: `${BACKEND_URL}/users/login`,
    REGISTER: `${BACKEND_URL}/users/register`,
    USER_REGISTER: `${BACKEND_URL}/users/user-register`,
    EXIST_USER: `${BACKEND_URL}/users/exist-user`,
    LOGIN_VERIFICATION: `${BACKEND_URL}/users/verify-user`,
    SEND_OTP: `${BACKEND_URL}/users/send-user-otp`,
    VERIFY_OTP_EMAIL: `${BACKEND_URL}/users/verify-user-otp`,
    FORGOT_PASSWORD: `${BACKEND_URL}/users/forgot-password`,
    DASHBOARD: {
        GET: `${BACKEND_URL}/dashboard`
    },
    GRAPHS: {
        GET: ({ params }) => {
            let url = `${BACKEND_URL}/dashboard/graphs`;
            url += `?page=${params?.page || 1}&count=${params?.count || 10}`;
            if (params?.search) {
                url += `&search=${params?.search}`
            }
            if (params?.organization) {
                url += `&organization=${params?.organization}`
            }
            return url;
        },
    },
    FORMS: ({ page = 1, count = 5, search = '', organization = '' }) => `${BACKEND_URL}/forms?page=${page}&count=${count}&search=${search}${organization ? `&organization=${organization}` : ''}`,
    USERS: {
        GET: (orgId) => `${BACKEND_URL}/users?organization=${orgId}`,
        GET_ALL: `${BACKEND_URL}/users`,
        GET_ALL_TYPE_USER: ({ page = 1, count = 10 }) => `${BACKEND_URL}/users?page=${page}&count=${count}`,
        GET_USER: ({ page = 1, count = 10, isActive = true }) => `${BACKEND_URL}/users?page=${page}&count=${count}&isActive=${isActive}`,
        UPDATE: (userId) => `${BACKEND_URL}/users/${userId}`,
        UPLOAD_PROFILE: (userId) => `${BACKEND_URL}/users/upload-user-pic/${userId}`,
        CHANGE_PASSWORD: (userId) => `${BACKEND_URL}/users/change-password/${userId}`
    },
    COMPANY: {
        UPLOAD_PROFILE: (companyId) => `${BACKEND_URL}/organization/upload-company-pic/${companyId}`,
        UPDATE: (companyId) => `${BACKEND_URL}/organization/update-org/${companyId}`,
        GET: (orgId) => `${BACKEND_URL}/organization/${orgId}`,
        CREATE: `${BACKEND_URL}/organization/create-org`
    },
    FISCAL_YEAR: {
        GET: `${BACKEND_URL}/organization/list/fiscal-year`,
        CREATE: `${BACKEND_URL}/organization/create-fiscal-year`,
        UPDATE: (orgId) => `${BACKEND_URL}/organization/update-fiscal-year/${orgId}`,
    },
    BUSINESS_HOURS: {
        GET: `${BACKEND_URL}/business-hours`,
        CREATE: `${BACKEND_URL}/business-hours/create-bns`,
        UPDATE: (businessId) => `${BACKEND_URL}/business-hours/update-bns/${businessId}`,
    },
    SHIFT_HOURS: {
        GET: (id) => `${BACKEND_URL}/business-hours/list/shift-hours/${id}`,
        CREATE: `${BACKEND_URL}/business-hours/create-shift-hours`,
        UPDATE: (shiftId) => `${BACKEND_URL}/business-hours/update-shift-hours/${shiftId}`,
        DELETE: (shiftId) => `${BACKEND_URL}/business-hours/delete-shift-hours/${shiftId}`,
    },
    CURRENCY: {
        GET: `${BACKEND_URL}/currencies`,
        CREATE: `${BACKEND_URL}/currencies/create-currency`,
        UPDATE: (id) => `${BACKEND_URL}/currencies/update-currency/${id}`,
    },
    SUB_CURRENCY: {
        CREATE: (id) => `${BACKEND_URL}/currencies/create-sub-currency/${id}`,
        UPDATE: (id) => `${BACKEND_URL}/currencies/update-sub-currency/${id}`,
    },
    PROFILE: {
        GET: `${BACKEND_URL}/profiles`,
        GET_BY_ID: (id) => `${BACKEND_URL}/profiles/${id}`,
        CREATE: `${BACKEND_URL}/profiles/create-profile`,
        UPDATE: (id) => `${BACKEND_URL}/profiles/update-profile/${id}`,
        DELETE: (id) => `${BACKEND_URL}/profiles/delete-profile/${id}`,
        DELETE_TO_TRANSFER: `${BACKEND_URL}/profiles/delete-to-transfer`
    },
    ROLE: {
        GET: `${BACKEND_URL}/roles`,
        CREATE: `${BACKEND_URL}/roles/create-role`,
        UPDATE: (id) => `${BACKEND_URL}/roles/update-role/${id}`,
        DELETE: (id) => `${BACKEND_URL}/roles/delete-role/${id}`,
        DELETE_TO_TRANSFER: `${BACKEND_URL}/roles/delete-to-transfer`
    },
    CREATE_MODULE: `${BACKEND_URL}/modules/create-module`,
    GET_MODULE: ({ page = 1, count = 10 }) => `${BACKEND_URL}/modules?page=${page}&count=${count}`,
    GET_ALL_MODULE_DATA: ({ page = 1, count = 100 } = {}) => `${BACKEND_URL}/modules?page=${page}&count=${count}`,
    GET_MODULE_BY_ID: `${BACKEND_URL}/modules`,
    CONVERT_SELECTED_MODULE_LIST: `${BACKEND_URL}/rules/list-selected-modules`,
    GET_CONVERT_MODULE_LIST: `${BACKEND_URL}/modules/fetch-convert-module`,
    UPDATE_MODULE_BY_ID: `${BACKEND_URL}/modules/update-module`,
    CONVERT: {
        CREATE: `${BACKEND_URL}/modules/create-convert-module`,
        GET: (moduleId) => `${BACKEND_URL}/modules/list-convert-module/${moduleId}`,
        GET_BY_ID: (moduleId, convertId) => `${BACKEND_URL}/modules/list-convert-module/${moduleId}/${convertId}`,
        UPDATE: (convertId) => `${BACKEND_URL}/modules/update-convert-module/${convertId}`,
    },
    SEND_MANUAL_EMAIL: `${BACKEND_URL}/form-sub-module/send-manual-email`,
    SEND_SCHEDULE_EMAIL: `${BACKEND_URL}/form-sub-module/send-schedule-email`,
    UPLOAD_ATTACHMENT: `${BACKEND_URL}/form-sub-module/upload-form-img-file`,
    SUBFORM_CREATE_MODULE: `${BACKEND_URL}/form-sub-module/create`,
    SUBFORM_GET_MODULE: `${BACKEND_URL}/form-sub-module`, // Module id
    SUBFORM_GET_MODULE_BY_ID: `${BACKEND_URL}/form-sub-module`, // Module id and subform id
    SUBFORM_UPDATE_MODULE_BY_ID: `${BACKEND_URL}/form-sub-module/update`, // Subform id
    SUBFORM_REMOVE_MODULE: `${BACKEND_URL}/form-sub-module/delete`, // Subform id
    SUBFORM_MODULE_LOG: (moduleId, subFormId) => `${BACKEND_URL}/form-sub-module/list/module-logs/${moduleId}/${subFormId}`, // Subform id
    WORKFLOW: {
        CREATE_WORKFLOW: `${BACKEND_URL}/rules/create-rule`,
        UPDATE_WORKFLOW: `${BACKEND_URL}/rules/update-rule`,
        GET_WORKFLOW: `${BACKEND_URL}/rules`,
        REMOVE_WORKFLOW: `${BACKEND_URL}/rules/delete-rule`,
        LIST_MODULES: (id) => `${BACKEND_URL}/rules/list-modules/${id}`,
        LIST_TEMPLATES: (id) => `${BACKEND_URL}/rules/list-email-templates/${id}`,
        UPLOAD_IMAGE: `${BACKEND_URL}/rules/upload-rules-img`,
    },
    SUBFORM_CUSTOM_VIEW: {
        CREATE: `${BACKEND_URL}/form-sub-module/create-custom-formsub-view`,
        UPDATE: (id) => `${BACKEND_URL}/form-sub-module/update-custom-formsub-view/${id}`,
        GET_ALL: (id) => `${BACKEND_URL}/form-sub-module/list/all-custom-formsub-view/${id}`,
        GET_BY_ID: (id) => `${BACKEND_URL}/form-sub-module/list/custom-formsub-view/${id}`,
    },
    GET_ALL_MODULE: `${BACKEND_URL}/modules/all-modules`,
    GET_APPROVAL: `${BACKEND_URL}/form-sub-module/get-approval`,
    UPDATE_APPROVAL_STATUS: `${BACKEND_URL}/form-sub-module/update-status`,
    UPDATE_REJECT_STATUS: `${BACKEND_URL}/form-sub-module/reject-status`,
    BULK_UPLOAD: `${BACKEND_URL}/form-sub-module/bulk-upload`,
    SUBFORM_GET_CREATED_RECORDS: `${BACKEND_URL}/form-sub-module/get-created-records`,
    SUBFORM_GRAPHICAL_STATUS: `${BACKEND_URL}/form-sub-module/get-graphical-status`,
    SUBFORM_CONVERT: `${BACKEND_URL}/form-sub-module/convert`,
    SUBFORM_CONVERTED: `${BACKEND_URL}/form-sub-module/list/converted-modules`,
    CREATE_WEBHOOK: `${BACKEND_URL}/webhooks/create-webhook`,
    REMOVE_MODULE: `${BACKEND_URL}/modules/delete-module`,
    GET_WEBHOOK_BY_MODULE_ID: `${BACKEND_URL}/webhooks`, // Module id
    GET_WEBHOOK_BY_ID: `${BACKEND_URL}/webhooks`, // Module id and webhooks id
    UPDATE_WEBHOOK_BY_ID: `${BACKEND_URL}/webhooks/update-webhook`, // webhooks id
    REMOVE_WEBHOOK_BY_ID: `${BACKEND_URL}/webhooks/delete-webhook`, // webhooks id
    EXITST_MATERIAL: `${BACKEND_URL}/webhooks/exist-material`,
    LIST_REF_MATERIAL: `${BACKEND_URL}/webhooks/list-ref-material`,
    CREATE_TABLE_COLUMN: `${BACKEND_URL}/form-sub-module/create-table-column`,
    UPDATE_TABLE_COLUMN: `${BACKEND_URL}/form-sub-module/update-table-column`,
    // below are settings temp url's, need to modify once get the original url
    GET_PERSONAL_SETTINGS: `${BACKEND_URL}/personal-settings`,
    UPDATE_PERSONAL_SETTINGS: `${BACKEND_URL}/personal-settings/update`,
    GET_USER_SETTINGS: `${BACKEND_URL}/user-settings`,
    CREATE_USER_SETTINGS: `${BACKEND_URL}/user-settings/create`,
    UPDATE_USER_SETTINGS: `${BACKEND_URL}/user-settings/update`,
    VARIANT_CONFIG: {
        GET: `${BACKEND_URL}/variant-config`,
        CREATE: `${BACKEND_URL}/variant-config/create`,
        DELETE: (id) => `${BACKEND_URL}/variant-config/delete/${id}`,
        UPDATE: (id) => `${BACKEND_URL}/variant-config/update/${id}`,
    },
    MODULES: {
        CREATE_MANAGE_MODULE: `${BACKEND_URL}/modules/create-manage-module`,
        GET_MANAGE_MODULES: `${BACKEND_URL}/modules/list/manage-modules`,
        UPDATE_MANAGE_MODULE: (id) => `${BACKEND_URL}/modules/update-manage-module/${id}`,
    },
    DASHBOARD_COMPONENTS: {
        SUBFORM_GET_ALL: `${BACKEND_URL}/dashboard/all-sub-modules`,
        CREATE_DASHBOARD: `${BACKEND_URL}/dashboard/create-dashboard`,
        UPDATE_DASHBOARD: `${BACKEND_URL}/dashboard/update-dashboard`,
        GET_DASHBOARD: `${BACKEND_URL}/dashboard`,
        GET_BYID_DASHBOARD: `${BACKEND_URL}/dashboard`,
        DELETE_DASHBOARD: `${BACKEND_URL}/dashboard/delete-dashboard`,
        CREATE_DASHBOARD_LAYOUT: `${BACKEND_URL}/dashboard/create-dashboard-layout`,
        UPDATE_DASHBOARD_LAYOUT: `${BACKEND_URL}/dashboard/update-dashboard-layout`,
        GET_DASHBOARD_LAYOUT: `${BACKEND_URL}/dashboard/list/dashboard-layout`,
        GET_BYID_DASHBOARD_LAYOUT: `${BACKEND_URL}/dashboard/list/dashboard-layout`,
        DELETE_DASHBOARD_LAYOUT: `${BACKEND_URL}/dashboard/delete-dashboard-layout`
    },
    LOGIN_HISTORY: {
        GET: (input) => `${BACKEND_URL}/login-history/user-login-count?dateInput=${input}`,
        GET_HISTORY: (email, input) => `${BACKEND_URL}/login-history/user-login-history?email=${email}&dateInput=${input}`
    },
    LOGOUT_HISTORY: (historyId) => `${BACKEND_URL}/login-history/user-logout/${historyId}`,
    AUDIT_LOG: {
        GET: ({ params }) => {
            let url = `${BACKEND_URL}/audit-log/list-audit-log`;
            const queryParams = [];

            // Add action filter if applicable
            if (params?.actions) queryParams.push(`actions=${params.actions}`);

            // Handle date filters based on filterType
            if (params?.filterType) {
                queryParams.push(`filterType=${params.filterType}`);
                if (params?.specificDate) {
                    queryParams.push(`specificDate=${params.specificDate}`);
                } else if (params?.startDate && params?.endDate) {
                    queryParams.push(`startDate=${params.startDate}`);
                    queryParams.push(`endDate=${params.endDate}`);
                }
            }

            // Add user filter if applicable
            if (params?.user) {
                queryParams.push(`user=${params.user}`);
            }

            if (queryParams.length) url += `?${queryParams.join('&')}`;
            return url;
        }
    },
}

const headers = (isMultipart = false) => {
    const header = {
        Authorization: `Bearer ${getItem("token")}`,
        "Content-Type": "application/json",
    }

    if (isMultipart) {
        header['Content-Type'] = 'multipart/form-data'
    }
    return header

};


// requests 
export const login = async (payload) => {
    try {
        try {
            return await axios.post(URL.LOGIN, payload);
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createModule = async (payload) => {
    try {
        console.log(headers())
        try {
            return await axios.post(URL.CREATE_MODULE, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getFormModuleList = async (payload) => {
    try {
        try {
            return await axios.get(URL?.GET_MODULE(payload), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}

export const getAllFormModuleList = async () => {
    try {
        return await axios.get(URL?.GET_ALL_MODULE_DATA(), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getModuleById = async (id) => {
    try {
        return await axios.get(`${URL.GET_MODULE_BY_ID}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getModuleByIdLookUp = async (id) => {
    try {
        return await axios.get(`${URL.GET_MODULE_BY_ID}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}


export const updateModuleById = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_MODULE_BY_ID}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getDashboard = async (payload) => {
    try {
        try {
            return await axios.get(URL.DASHBOARD.GET, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}

export const getAllGraphs = async (payload) => {
    const { params } = payload;
    try {
        try {
            return await axios.get(URL.GRAPHS.GET({ params }), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}

export const getForms = async (payload) => {
    try {
        try {
            return await axios.get(URL?.FORMS(payload), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getUser = async (payload) => {
    try {
        try {
            return await axios.get(URL.USERS.GET(payload), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getUserById = async (id) => {
    try {
        try {
            return await axios.get(`${URL.USERS.GET_ALL}/${id}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getOrganization = async (id) => {
    try {
        try {
            let orgId = getItem('organization')
            return await axios.get(`${URL?.COMPANY?.GET(orgId)}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const updateUserById = async (data) => {
    try {
        return await axios.put(URL?.USERS?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
export const deleteUserById = async (id) => {
    try {
        return await axios.delete(URL?.USERS?.UPDATE(id), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
export const uploadProfilePictureById = async (data) => {
    try {
        return await axios.put(URL?.USERS?.UPLOAD_PROFILE(data.id), data?.payload, { headers: headers(true) });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
export const changePassword = async (data) => {
    try {
        return await axios.put(URL?.USERS?.CHANGE_PASSWORD(data.userId), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const createCompany = async (payload) => {
    try {
        return await axios.post(`${URL?.COMPANY?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
export const uploadCompanyPictureById = async (data) => {
    try {
        return await axios.put(URL?.COMPANY?.UPLOAD_PROFILE(data.id), data?.payload, { headers: headers(true) });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
export const updateCompanyById = async (data) => {
    try {
        return await axios.put(URL?.COMPANY?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// fiscal

export const getFiscalYear = async (id) => {
    try {
        try {
            return await axios.get(`${URL?.FISCAL_YEAR?.GET}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createFiscalYear = async (payload) => {
    try {
        return await axios.post(`${URL?.FISCAL_YEAR?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateFiscalYear = async (data) => {
    try {
        return await axios.put(URL?.FISCAL_YEAR?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// business hours

export const getBusinessHours = async (id) => {
    try {
        try {
            return await axios.get(`${URL?.BUSINESS_HOURS?.GET}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createBusinessHours = async (payload) => {
    try {
        return await axios.post(`${URL?.BUSINESS_HOURS?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateBusinessHours = async (data) => {
    try {
        return await axios.put(URL?.BUSINESS_HOURS?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// shift hours

export const getShiftHours = async (id) => {
    try {
        try {
            return await axios.get(`${URL?.SHIFT_HOURS?.GET(id)}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createShiftHours = async (payload) => {
    try {
        return await axios.post(`${URL?.SHIFT_HOURS?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateShiftHours = async (data) => {
    try {
        // debugger
        // return await axios.put(URL?.SHIFT_HOURS?.UPDATE(data.id), data?.payload, { headers: headers() });
        return await axios.put(`${URL.SHIFT_HOURS?.UPDATE(data?.id)}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteShiftHours = async (id) => {
    try {
        return await axios.delete(URL?.SHIFT_HOURS?.DELETE(id), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// currency
export const getCurrency = async (id) => {
    try {
        try {
            return await axios.get(`${URL?.CURRENCY?.GET}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createCurrency = async (payload) => {
    try {
        return await axios.post(`${URL?.CURRENCY?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateCurrency = async (data) => {
    try {
        return await axios.put(URL?.CURRENCY?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// sub currency
export const createSubCurrency = async (data) => {
    try {
        return await axios.post(`${URL?.SUB_CURRENCY?.CREATE(data.id)}`, data.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateSubCurrency = async (data) => {
    try {
        return await axios.put(URL?.SUB_CURRENCY?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// profile
export const getProfiles = async () => {
    try {
        try {
            return await axios.get(`${URL?.PROFILE?.GET}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getProfileById = async (id) => {
    try {
        try {
            return await axios.get(`${URL?.PROFILE?.GET_BY_ID(id)}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createProfile = async (payload) => {
    try {
        return await axios.post(`${URL?.PROFILE?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updatePermission = async (data) => {
    try {
        return await axios.put(URL?.PROFILE?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteProfile = async (id) => {
    try {
        return await axios.delete(URL?.PROFILE?.DELETE(id), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteToTransfer = async (data) => {
    try {
        return await axios.put(URL?.PROFILE?.DELETE_TO_TRANSFER, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// roles and sharing

export const getRoles = async () => {
    try {
        try {
            return await axios.get(`${URL?.ROLE?.GET}`, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createRole = async (payload) => {
    try {
        return await axios.post(`${URL?.ROLE?.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateRole = async (data) => {
    try {
        return await axios.put(URL?.ROLE?.UPDATE(data.id), data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteRole = async (id) => {
    try {
        return await axios.delete(URL?.ROLE?.DELETE(id), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteToTransferRole = async (data) => {
    try {
        return await axios.put(URL?.ROLE?.DELETE_TO_TRANSFER, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// register
export const register = async (payload) => {
    try {
        try {
            return await axios.post(URL.REGISTER, payload);
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const userRegister = async (payload) => {
    try {
        try {
            return await axios.post(URL.USER_REGISTER, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const verifyEmail = async (payload) => {
    try {
        return await axios.post(URL.LOGIN_VERIFICATION, payload);
    } catch (err) {
        return { error: err.response?.data || 'Verification failed. Please try again.' };
    }
};

export const sendOtp = async (payload) => {
    try {
        return await axios.post(URL.SEND_OTP, payload);
    } catch (err) {
        return { error: err.response?.data || 'Verification failed. Please try again.' };
    }
};

export const verifyOtpEmail = async (payload) => {
    try {
        return await axios.post(URL.VERIFY_OTP_EMAIL, payload);
    } catch (err) {
        return { error: err.response?.data || 'Verification failed. Please try again.' };
    }
};

export const forgotPassword = async (payload) => {
    try {
        return await axios.post(URL.FORGOT_PASSWORD, payload);
    } catch (err) {
        return { error: err.response?.data || 'Password reset failed. Please try again.' };
    }
};

export const checkExistUser = async (payload) => {
    try {
        return await axios.post(URL.EXIST_USER, payload);
    } catch (err) {
        if (err.response.data.message === "User Already Registered. Please use different one") {
            return err.response.data;
        }
        throw err.response?.data || 'Error checking user existence';
    }
};

export const getAllFormModuleData = async () => {
    try {
        try {
            return await axios.get(URL.GET_ALL_MODULE, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getAllFormModuleTableData = async () => {
    try {
        try {
            return await axios.get(URL.GET_ALL_MODULE, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const removeModuleById = async (moduleId) => {
    try {
        return await axios.delete(`${URL.REMOVE_MODULE}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// Sub form apis
export const createSubFormModule = async (payload) => {
    try {
        try {
            return await axios.post(URL.SUBFORM_CREATE_MODULE, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getSubFormModuleList = async (payload) => {
    try {
        let isPagination = (payload?.hasOwnProperty('page') ? `?page=${payload?.page}&count=${payload?.count}` : "");
        return await axios.get(`${URL.SUBFORM_GET_MODULE}/${payload?.id}${isPagination}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getSubFormModuleListById = async (data) => {
    try {
        return await axios.get(`${URL.SUBFORM_GET_MODULE_BY_ID}/${data?.moduleId}/${data?.subModuleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateSubFormModuleById = async (data) => {
    try {
        return await axios.put(`${URL.SUBFORM_UPDATE_MODULE_BY_ID}/${data?.subModuleId}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const removeSubFormModuleById = async (subModuleId) => {
    try {
        return await axios.delete(`${URL.SUBFORM_REMOVE_MODULE}/${subModuleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const bulkUploadSubFormModule = async (payload) => {
    try {
        return await axios.post(`${URL.BULK_UPLOAD}/${payload?.moduleId}`, payload?.formData, { headers: headers(true) });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const createTableColumnSubFormModule = async (payload) => {
    try {
        return await axios.post(URL.CREATE_TABLE_COLUMN, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const updateTableColumnSubFormModule = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_TABLE_COLUMN}/${data?.tableColumnId}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const getSubFormModuleCreatedRecords = async (moduleId) => {
    try {
        return await axios.get(`${URL.SUBFORM_GET_CREATED_RECORDS}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getSubFormModuleGraphicalStatus = async (payload) => {
    try {
        return await axios.post(`${URL.SUBFORM_GRAPHICAL_STATUS}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const convertSubformData = async (payload) => {
    try {
        return await axios.post(`${URL.SUBFORM_CONVERT}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const convertedSubformData = async (moduleId) => {
    try {
        return await axios.get(`${URL.SUBFORM_CONVERTED}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}
// End sub form apis

// Webhooks apis

export const createWebhook = async (payload) => {
    try {
        try {
            return await axios.post(URL.CREATE_WEBHOOK, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getWebhookListByModuleId = async (moduleId) => {
    try {
        return await axios.get(`${URL.GET_WEBHOOK_BY_MODULE_ID}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getWebhookListById = async (data) => {
    try {
        return await axios.get(`${URL.GET_WEBHOOK_BY_ID}/${data?.moduleId}/${data?.webhookId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateWebhookById = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_WEBHOOK_BY_ID}/${data?.webhookId}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const removeWebhookById = async (webhookId) => {
    try {
        return await axios.delete(`${URL.REMOVE_WEBHOOK_BY_ID}/${webhookId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const existMaterialWebhook = async (payload) => {
    try {
        try {
            return await axios.post(URL.EXITST_MATERIAL, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const listRefMaterialWebhook = async (payload) => {
    try {
        try {
            return await axios.post(URL.LIST_REF_MATERIAL, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};
// End Webhooks apis

// export const getAllUsers = async () => {
//     try {
//         try {
//             return await axios.get(URL.USERS.GET_ALL, { headers: headers() });
//         } catch (err) {
//             errorHandler(err);
//         }
//     } catch (err) {
//         throw err;
//     }
// };

export const getAllUsers = async (payload) => {
    try {
        try {
            // return await axios.get(`${URL.USERS.GET_ALL}?page=${payload.page}&count=${payload.perPage}`, { headers: headers() });
            return await axios.get(URL?.USERS?.GET_USER(payload), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getAllTypeUsers = async (payload) => {
    try {
        try {
            // return await axios.get(`${URL.USERS.GET_ALL}?page=${payload.page}&count=${payload.perPage}`, { headers: headers() });
            return await axios.get(URL?.USERS?.GET_ALL_TYPE_USER(payload), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getAllUsersData = async () => {
    try {
        try {
            // return await axios.get(`${URL.USERS.GET_ALL}?page=${payload.page}&count=${payload.perPage}`, { headers: headers() });
            return await axios.get(URL?.USERS?.GET_ALL, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const createWorkflow = async (payload) => {
    try {
        try {
            return await axios.post(URL.WORKFLOW.CREATE_WORKFLOW, payload, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
};

export const getWorkflowList = async () => {
    try {
        try {
            return await axios.get(URL.WORKFLOW.GET_WORKFLOW, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}
export const getWorkflowById = async (id) => {
    try {
        return await axios.get(`${URL.WORKFLOW.GET_WORKFLOW}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getWorkflowModuleList = async (id) => {
    try {
        try {
            return await axios.get(URL.WORKFLOW.LIST_MODULES(id), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}

export const getEmailTemplateList = async (id) => {
    try {
        try {
            return await axios.get(URL.WORKFLOW.LIST_TEMPLATES(id), { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}

export const getApprovalList = async () => {
    try {
        try {
            return await axios.get(URL.GET_APPROVAL, { headers: headers() });
        } catch (err) {
            errorHandler(err);
        }
    } catch (err) {
        throw err;
    }
}
export const getApprovalById = async (id) => {
    try {
        return await axios.get(`${URL.GET_APPROVAL}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateWorkflow = async (data) => {
    try {
        return await axios.put(`${URL.WORKFLOW.UPDATE_WORKFLOW}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const uploadWorkflowImage = async (data) => {
    try {
        return await axios.post(URL?.WORKFLOW?.UPLOAD_IMAGE, data?.payload, { headers: headers(true) });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateApprovalStatus = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_APPROVAL_STATUS}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateRejectStatus = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_REJECT_STATUS}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const removeWorkflowById = async (id) => {
    try {
        return await axios.delete(`${URL.WORKFLOW.REMOVE_WORKFLOW}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// settings api calling..

export const getPersonalSettingsById = async (id) => {
    try {
        return await axios.get(`${URL.GET_PERSONAL_SETTINGS}/${id}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updatePersonalSettings = async (data) => {
    try {
        return await axios.put(`${URL.UPDATE_PERSONAL_SETTINGS}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

// Start Variant Config
export const getVariantConfig = async () => {
    try {
        return await axios.get(URL.VARIANT_CONFIG.GET, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const createVariantConfig = async (payload) => {
    try {
        return await axios.post(URL.VARIANT_CONFIG.CREATE, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const deleteVariantConfigById = async (id) => {
    try {
        return await axios.delete(URL.VARIANT_CONFIG.DELETE(id), { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};

export const updateVariantConfigById = async (payload) => {
    try {
        return await axios.put(URL.VARIANT_CONFIG.UPDATE(payload?._id), payload?.data, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
};
// End Variant Config

const errorHandler = (err) => {
    try {
        const { response } = err;
        const { status } = response;
        if (status === 401) {
            logout();
        } else {
            if (status === 404 && err?.response?.data?.message === 'User Not Found') {
                logout();
            } else {
                throw err;
            }
        }
    } catch (err) {
        throw err;
    }
}

// Manage Navbar
export const createManageModule = async (payload) => {
    try {
        return await axios.post(URL.MODULES.CREATE_MANAGE_MODULE, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
    }
};

export const getManageModules = async () => {
    try {
        return await axios.get(URL.MODULES.GET_MANAGE_MODULES, { headers: headers() });
    } catch (err) {
        errorHandler(err);
    }
};

export const updateManageModule = async (data) => {
    try {
        return await axios.put(`${URL.MODULES.UPDATE_MANAGE_MODULE(data?.id)}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
    }
};

// DASHBOARD_COMPONENTS
export const getAllSubFormModules = async (moduleId) => {
    try {
        return await axios.get(`${URL.DASHBOARD_COMPONENTS.SUBFORM_GET_ALL}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const createDashboardComponent = async (payload) => {
    try {
        return await axios.post(`${URL.DASHBOARD_COMPONENTS.CREATE_DASHBOARD}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateDashboardComponent = async (data) => {
    try {
        return await axios.put(`${URL.DASHBOARD_COMPONENTS.UPDATE_DASHBOARD}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getAllDashboardComponent = async () => {
    try {
        return await axios.get(`${URL.DASHBOARD_COMPONENTS.GET_DASHBOARD}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getByIdDashboardComponent = async (dashboardId) => {
    try {
        return await axios.get(`${URL.DASHBOARD_COMPONENTS.GET_BYID_DASHBOARD}/${dashboardId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteDashboardComponent = async (dashboardId) => {
    try {
        return await axios.delete(`${URL.DASHBOARD_COMPONENTS.DELETE_DASHBOARD}/${dashboardId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const createDashboardComponentLayout = async (payload) => {
    try {
        return await axios.post(`${URL.DASHBOARD_COMPONENTS.CREATE_DASHBOARD_LAYOUT}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateDashboardComponentLayout = async (data) => {
    try {
        return await axios.put(`${URL.DASHBOARD_COMPONENTS.UPDATE_DASHBOARD_LAYOUT}/${data?.id}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getAllDashboardComponentLayout = async () => {
    try {
        return await axios.get(`${URL.DASHBOARD_COMPONENTS.GET_DASHBOARD_LAYOUT}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getByIdDashboardComponentLayout = async (dashboardId) => {
    try {
        return await axios.get(`${URL.DASHBOARD_COMPONENTS.GET_BYID_DASHBOARD_LAYOUT}/${dashboardId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const deleteDashboardComponentLayout = async (dashboardId) => {
    try {
        return await axios.delete(`${URL.DASHBOARD_COMPONENTS.DELETE_DASHBOARD_LAYOUT}/${dashboardId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const fetchIp = async () => {
    try {
        const response = await axios.get(IPIFY_URL);
        return response.data.ip;
    } catch (err) {
        console.error('Error fetching IP address:', err);
        throw err;
    }
};

export const fetchLocation = async (ip) => {
    try {
        const response = await axios.get(`${IPINFO_URL}/${ip}`, {
            params: { token: IPINFO_TOKEN },
        });
        const { city, region, country } = response.data;
        return { city, state: region, country };
    } catch (err) {
        console.error('Error fetching location from IPinfo:', err);
        throw err;
    }
};


export const convertSelectedModuleList = async (moduleId) => {
    try {
        return await axios.get(`${URL.CONVERT_SELECTED_MODULE_LIST}/${moduleId}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getConvertModuleList = async (payload) => {
    try {
        return await axios.post(`${URL.GET_CONVERT_MODULE_LIST}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

/* Convert CURD */
export const createConvertModule = async (payload) => {
    try {
        return await axios.post(`${URL.CONVERT.CREATE}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getConvertModule = async (payload) => {
    try {
        return await axios.get(`${URL.CONVERT.GET(payload)}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getByIDConvertModule = async (payload) => {
    try {
        return await axios.get(`${URL.CONVERT.GET_BY_ID(payload?.moduleId, payload?.convertId)}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const updateConvertModule = async (data) => {
    try {
        return await axios.put(`${URL.CONVERT.UPDATE(data?.id)}`, data?.payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const subFormModuleLog = async (payload) => {
    try {
        return await axios.get(`${URL.SUBFORM_MODULE_LOG(payload?.moduleId, payload?.subFormId)}`, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const getLoginUserCount = async (input) => {
    try {
        const response = await axios.get(URL.LOGIN_HISTORY.GET(input), { headers: headers() });
        return response.data;
    } catch (err) {
        console.error('Error fetching user login count:', err);
        throw err;
    }
};

export const getLoginUserHistory = async (payload) => {
    try {
        const response = await axios.get(URL.LOGIN_HISTORY.GET_HISTORY(payload.email, payload.input), { headers: headers() });
        return response.data;
    } catch (err) {
        console.error('Error fetching user login history:', err);
        throw err;
    }
};

export const getAuditLogs = async (payload) => {
    const { params } = payload;
    try {
        const response = await axios.get(URL.AUDIT_LOG.GET({ params }), { headers: headers() });
        return response.data;
    } catch (err) {
        console.error('Error fetching audit logs:', err);
        throw err;
    }
};

export const logout = async () => {
    try {
        const historyId = JSON.parse(localStorage.getItem('history_Id'));

        if (!historyId) {
            localStorage.clear();
            window.location.href = "/auth/login";
        }
        const response = await axios.put(URL.LOGOUT_HISTORY(historyId), {}, {});

        localStorage.clear();
        window.location.href = "/auth/login";

        return response.data;
    } catch (err) {
        console.error('Error during logout:', err);
        throw err;
    }
};

export const createSubFormCustomView = async (payload) => {
    try {
        return await axios.post(URL.SUBFORM_CUSTOM_VIEW.CREATE, payload, { headers: headers() });
    } catch (err) {
        console.error('Error fetching user login history:', err);
        throw err;
    }
};

export const getAllSubFormCustomView = async (moduleId) => {
    try {
        return await axios.get(URL.SUBFORM_CUSTOM_VIEW.GET_ALL(moduleId), { headers: headers() });
    } catch (err) {
        console.error('Error fetching user login history:', err);
        throw err;
    }
};

export const getByIdSubFormCustomView = async (customViewId) => {
    try {
        return await axios.get(URL.SUBFORM_CUSTOM_VIEW.GET_ALL(customViewId), { headers: headers() });
    } catch (err) {
        console.error('Error fetching user login history:', err);
        throw err;
    }
};

export const updateSubFormCustomView = async (data) => {
    try {
        return await axios.put(URL.SUBFORM_CUSTOM_VIEW.GET_ALL(data?.customViewId), data?.payload, { headers: headers() });
    } catch (err) {
        console.error('Error fetching user login history:', err);
        throw err;
    }
};
// export const logout = async () => {
//     try {
//         const historyId = JSON.parse(localStorage.getItem('history_Id'));
//         if (!historyId) {
//             // throw new Error('No history_Id found in localStorage');
//             localStorage.clear();
//             window.location.reload();
//         }
//         const response = await axios.put(URL.LOGOUT_HISTORY(historyId), {}, {});

//         localStorage.clear();
//         window.location.reload();

//         return response.data;
//     } catch (err) {
//         console.error('Error during logout:', err);
//         throw err;
//     }
// };

// send mail from sub module

export const sendManualMail = async (payload) => {
    try {
        return await axios.post(`${URL?.SEND_MANUAL_EMAIL}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const sendScheduleMail = async (payload) => {
    try {
        return await axios.post(`${URL?.SEND_SCHEDULE_EMAIL}`, payload, { headers: headers() });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}

export const attachFile = async (data) => {
    try {
        return await axios.post(URL?.UPLOAD_ATTACHMENT, data?.payload, { headers: headers(true) });
    } catch (err) {
        errorHandler(err);
        throw err;
    }
}