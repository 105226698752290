import React, { useEffect, useState } from "react";
import DynamicTable from "../../formModules/dynamicTable";
import CustomInput from "../../formModules/customInputs";
import { Button, Modal } from 'react-bootstrap';
import { Helpers } from "../../../Helpers";
import { useDispatch, useSelector } from "react-redux";
import AggregateFormItem from "./AggregateFormItem";
import { getAllUserListStart } from "../../../Store/Slices/userSlice";
import { Utils } from "../../../Utils";
import { createSubFormStart, getSubFormListStart, resetExit } from "../../../Store/Slices/subFormSlice";
import Loader from "../../Loader";
import { toast } from 'react-toastify';
import Toast from "../../Toast";
import { getItem } from "../../../Services/localService";
import AssignmentModuleChange from "./AssignmentModuleChange";
import AssignmentSubformChange from "./AssignmentSubformChange";
import { CheckBoxModals } from "./CheckBoxModals";
import AddParticipants from "./AddParticipants";
import SendInvitationModal from "./SendInvitationModal";

const CreateAssignment = ({ show, handleClose, moduleData, basedModuleStoreData, basedSubFormData, subFormId, basedModuleId }) => {
    let currentUserData = getItem('user');
    let users = useSelector((state) => state?.user);
    let [storeData, setStoreData] = useState(null);
    let [moduleStoreData, setModuleStoreData] = useState(null);
    let subFormData = useSelector((state) => (state?.subForm || ''));
    let dispatch = useDispatch();
    let [errors, setErrors] = useState({});
    let [listItems, setListItems] = useState(null);
    let [userList, setUserList] = useState([]);
    let [showToast, setShowToast] = useState(false);
    let [formSubmit, setFormSubmit] = useState(false);
    let [mainModuleSubFormChangeModal, setMainModuleSubFormChangeModal] = useState(null);
    let keys = {
        "variants": "taskVariants",
        "tableColumns": "taskTableColumns",
        "formsubmodules": "taskFormsubmodules",
        "totalCount": "taskTotalCount",
        "modules": "taskModules",
        "lookupData": "taskLookupData",
        "workflowRules": "taskWorkflowRules",
        "sapStatus": "taskSapStatus",
        "message": "taskMessage",
        "isLoading": "taskIsLoading",
        "status": "taskStatus"
    }
    let [subFormFieldLabel, setSubFormFieldLabel] = useState("");
    let [subFormName, setSubFormName] = useState("");
    let [defaultSubFormChangeData, setDefaultSubFormChangeData] = useState(null);
    let [defaultModuleSubFormChangeData, setDefaultModuleSubFormChangeData] = useState(null);
    let [basedSubFormId, setBasedSubFormId] = useState(null);
    let [basedModuleId2, setBasedModuleId2] = useState(null);
    let checkKeys = {
        Tasks: ["Lead_Name"],
        Calls: ["Contact_Name"],
        Meetings: ["Contact"]
    }
    let [basedSubFormId2, setBasedSubFormId2] = useState(subFormId || "");
    let [basedModuleId3, setBasedModuleId3] = useState(basedModuleId || "");
    let [OpenCheckBoxModel, setOpenCheckBoxModel] = useState(null);
    let [openCheckBoxPopup, setOpenCheckBoxPopup] = useState(null);
    let [openParticipantModal, setOpenParticipantModal] = useState(null);
    let [sendInvitationModal, setSendInvitationModal] = useState(false);

    let openCheckBoxFieldModal = (data) => {
        let checkBoxModel = CheckBoxModals[data?.modalName];
        if (checkBoxModel) {
            setOpenCheckBoxModel(() => checkBoxModel);
            setOpenCheckBoxPopup(data?.label);
        } else {
            setOpenCheckBoxModel(null);
            setOpenCheckBoxPopup(null);
        }
    }

    let setValue = (key, value) => {
        setStoreData((prevStoreData) => {
            return {
                ...prevStoreData,
                data: prevStoreData?.data?.map((item) => {
                    if (item?.section_data?.hasOwnProperty(key)) {
                        return {
                            ...item,
                            section_data: {
                                ...item.section_data,
                                [key]: value,
                            },
                        };
                    }
                    return item;
                }),
            };
        });
        setOpenCheckBoxModel(null);
        setOpenCheckBoxPopup(null);
    }

    useEffect(() => {
        if (defaultModuleSubFormChangeData && mainModuleSubFormChangeModal) {
            if (defaultModuleSubFormChangeData?.moduleId !== basedModuleId2) {
                try {
                    if (Helpers.replaceData(mainModuleSubFormChangeModal?.field_label) === "Related_To") {
                        setBasedModuleId3(defaultModuleSubFormChangeData?.name?._id);
                    }
                    setValue(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), defaultModuleSubFormChangeData?.name);
                    setBasedModuleId2(defaultModuleSubFormChangeData?.moduleId);
                    setMainModuleSubFormChangeModal(null);
                } catch (error) { }
            }
        }
    }, [defaultModuleSubFormChangeData]);

    useEffect(() => {
        if (defaultSubFormChangeData && mainModuleSubFormChangeModal) {
            if (defaultSubFormChangeData?.subFormId !== basedSubFormId) {
                try {
                    let setValBol = checkKeys[moduleData?.name || ""]?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label));
                    setBasedSubFormId2(setValBol ? defaultSubFormChangeData?.name?._id : basedModuleId2);
                    setValue(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), setValBol ? defaultSubFormChangeData?.name?.name : defaultSubFormChangeData?.name);
                    setBasedSubFormId(defaultSubFormChangeData?.subFormId);
                    setMainModuleSubFormChangeModal(null);
                } catch (error) { }
            }
        }
    }, [defaultSubFormChangeData]);

    useEffect(() => {
        if (basedModuleStoreData) {
            setSubFormFieldLabel(Utils.getDefaultSubFormNameField(basedModuleStoreData?.fields?.sections));
        }
    }, [basedModuleStoreData]);

    useEffect(() => {
        if (subFormFieldLabel) {
            setSubFormName(Utils.getFieldToData(subFormFieldLabel, basedSubFormData?.data || [], subFormData?.taskModules));
        }
    }, [subFormFieldLabel]);

    useEffect(() => {
        if (users?.data?.length > 0) {
            setUserList(users?.data);
        }
    }, [users?.data]);

    let formatDataStructures = (data = null, isValidate = false) => {
        let format = [];
        let newErrors = {};
        let assignDefaultValues = (item) => {
            let default_value = item?.extra_fields?.default_value;
            if (item?.input_type === 'multi-select' && default_value && default_value !== "None") {
                return [{ name: default_value }];
            } else {
                return default_value || "";
            }
        }
        data?.sections?.forEach((section, sectionIndex) => {
            if (!isValidate) {
                if (!format?.[sectionIndex]) {
                    format[sectionIndex] = {
                        section_name: section?.section_name,
                        section_data: {}
                    }
                }
            }
            if (section?.subform) {
                if (section?.selected_list?.length > 0 && !isValidate) {
                    format[sectionIndex].section_data["table_data"] = [
                        Object.assign({}, ...section.selected_list.map(x => ({
                            [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                        })))
                    ];
                }
            } else {
                Object.entries(section?.dropped_list || {})?.forEach(([key, value]) => {
                    value?.forEach(item => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            let singularFormMapping = {
                                Tasks: {
                                    Lead_Name: subFormName || "",
                                    Related_To: {
                                        _id: basedModuleId,
                                        name: basedModuleStoreData?.name || ""
                                    }
                                },
                                Calls: {
                                    Contact_Name: subFormName || "",
                                    Related_To: {
                                        _id: basedModuleId,
                                        name: basedModuleStoreData?.name || ""
                                    }
                                },
                                Meetings: {
                                    Contact: subFormName || "",
                                    Related_To: {
                                        _id: basedModuleId,
                                        name: basedModuleStoreData?.name || ""
                                    }
                                }
                            };
                            let singularFormData = singularFormMapping[data?.singular_form] || {};
                            format[sectionIndex].section_data[field_label] = singularFormData[field_label] !== undefined ? singularFormData[field_label] : assignDefaultValues(item);
                        } else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            newErrors[field_label] = errors?.[field_label];
                        } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        }
                    })
                });
            }
        });
        if (!isValidate) {
            setStoreData({
                data: format,
            });
        } else {
            setErrors(newErrors);
        }
        return Object.keys(newErrors).length === 0;
    }

    useEffect(() => {
        if (moduleData?._id?.length > 0) {
            dispatch(getSubFormListStart({ id: moduleData?._id, keys }));
        }
    }, [moduleData]);

    useEffect(() => {
        if (subFormData?.taskModules) {
            setModuleStoreData(subFormData?.taskModules);
        }
    }, [subFormData?.taskModules]);

    useEffect(() => {
        if (moduleStoreData?._id?.length > 0) {
            let fields = moduleStoreData?.fields;
            let moduleData = Utils.getModuleFields(fields?.sections || []);
            let moduleFields = [...(moduleData?.moduleFields || []), ...(moduleData?.subformList || [])];
            let user = moduleFields.some(x => x?.fields?.input_type === "user");
            if (user && !userList?.length) {
                dispatch(getAllUserListStart({ page: 1, count: 100 }));
            }
            setListItems(fields);
            formatDataStructures(fields);
        } else {
            setListItems(null);
        }
    }, [moduleStoreData]);

    const submit = (sendEmailModal = false, mailAllow = false) => {
        if (formatDataStructures(subFormData?.taskModules?.fields, true)) {
            let participantsData = Utils.getSubFormFieldToData("Participants", storeData?.data) || {};
            if (participantsData?.selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) + participantsData?.inviteEmails?.length > 0 && !sendEmailModal) {
                setSendInvitationModal(true);
                return;
            }
            let allData = {
                moduleId: moduleData?._id,
                ...storeData, log_details: {
                    user: `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`,
                    details: `${Utils.getFieldString(storeData?.data, subFormData?.taskModules)}`
                },
                basedSubFormId: basedSubFormId2,
                basedModuleId: basedModuleId3,
                ...(moduleData?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Reminder", storeData?.data)) ? { reminder: { ...Utils.getSubFormFieldToData("Reminder", storeData?.data) } } : {}),
                ...(moduleData?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Repeat", storeData?.data)) ? { repeat: Utils.generateRepeatDates({ ...Utils.getSubFormFieldToData("Repeat", storeData?.data), dueDate: Utils.getSubFormFieldToData("Due_Date", storeData?.data) || "" }) } : {}),
                ...(mailAllow ? { participants: [...participantsData?.selectedData.flatMap(section => section.data.map(entry => entry.email)), ...participantsData?.inviteEmails?.map(y => y?.value)] } : {}),
                ...(mailAllow ? { participants_cc: [currentUserData?.email] } : {})
            };
            dispatch(createSubFormStart({ data: allData, keys }));
            setErrors({});
            setFormSubmit(true);
        } else {
            setShowToast(true);
            toast.error("kindy fill the all required details", {
                autoClose: 1000,
                onClose: () => {
                    setShowToast(false);
                }
            });
        }
    }

    useEffect(() => {
        if (subFormData?.exit && formSubmit) {
            dispatch(resetExit());
            handleClose();
        }
    }, [subFormData?.exit, formSubmit]);

    return (
        <>
            {showToast && <Toast />}
            <Modal show={show && !mainModuleSubFormChangeModal && !OpenCheckBoxModel && !openParticipantModal && !sendInvitationModal} onHide={handleClose} size="lg" dialogClassName="custom-modal-size" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Create {moduleData?.name || ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${subFormData?.taskIsLoading ? "vh-100" : ""}`}>
                    {subFormData?.taskIsLoading && (
                        <Loader isVisible={true} isModal={true} overall={false} />
                    )}
                    {!subFormData?.taskIsLoading && (
                        <main className="col-lg-12">
                            {Helpers.arrayLength(moduleStoreData?.fields?.sections) && (
                                moduleStoreData?.fields?.sections.map((section, sectionIndex) => (
                                    <div className="row mb-40" key={sectionIndex}>
                                        <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                                        {
                                            section?.subform ?
                                                <>
                                                    {
                                                        (section?.selected_list?.length > 0 && storeData?.data?.[sectionIndex]?.section_data?.["table_data"]?.length > 0) && (
                                                            <div className="col-md-12">
                                                                <div className="tbl-container rounded-table">
                                                                    <DynamicTable section={section} storeData={storeData} sectionIndex={sectionIndex} setStoreData={setStoreData} preview={false} users={userList} lookupData={subFormData?.taskLookupData} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (section?.aggregate_list?.length > 0 &&
                                                            <AggregateFormItem aggregate_list={section?.aggregate_list} storeData={storeData} setStoreData={setStoreData} sectionIndex={sectionIndex} />
                                                        )
                                                    }
                                                </> :
                                                <>
                                                    {section?.dropped_list && Object.entries(section?.dropped_list || {}).map(([key, value], index) => (
                                                        value?.length > 0 && (
                                                            <div key={index} className="col-md-12">
                                                                {value.map(x => (
                                                                    <CustomInput
                                                                        key={x?.id}
                                                                        items={x}
                                                                        sectionIndex={sectionIndex}
                                                                        preview={false}
                                                                        storeData={storeData}
                                                                        setStoreData={setStoreData}
                                                                        error={errors[Helpers.replaceData(x.extra_fields.field_label)]}
                                                                        setErrors={setErrors}
                                                                        listItems={listItems}
                                                                        handleBlurMaterialNumber={() => { }}
                                                                        handleBlurReferenceMaterial={() => { }}
                                                                        users={userList}
                                                                        lookupData={subFormData?.taskLookupData}
                                                                        variants={subFormData?.taskVariants}
                                                                        modules={subFormData?.taskModules}
                                                                        setMainModuleSubFormChangeModal={setMainModuleSubFormChangeModal}
                                                                        customInputs={checkKeys[moduleData?.name || '']}
                                                                        openCheckBoxFieldModal={(val) => openCheckBoxFieldModal(val)}
                                                                        openParticipantModal={(val => setOpenParticipantModal(val))}
                                                                    />
                                                                ))}
                                                            </div>
                                                        )
                                                    ))}
                                                </>
                                        }
                                    </div>
                                ))
                            )
                            }
                        </main>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => submit(false, false)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal >
            {(mainModuleSubFormChangeModal && mainModuleSubFormChangeModal?.is_module_lookup) && <AssignmentModuleChange show={!!mainModuleSubFormChangeModal} handleClose={() => {
                setMainModuleSubFormChangeModal(null);
            }} moduleId={(Utils.getSubFormFieldToData(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), storeData?.data)?._id || "")} setDefaultModuleSubFormChangeData={setDefaultModuleSubFormChangeData} />}
            {(mainModuleSubFormChangeModal && !mainModuleSubFormChangeModal?.is_module_lookup) && <AssignmentSubformChange show={!!mainModuleSubFormChangeModal} handleClose={() => {
                setMainModuleSubFormChangeModal(null);
            }} moduleId={checkKeys[moduleData?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? (Utils.getSubFormFieldToData("Related_To", storeData?.data)?._id || "") : mainModuleSubFormChangeModal?.moduleId} subFormId={checkKeys[moduleData?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? basedSubFormId2 : Utils.getSubFormFieldToData(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), storeData?.data)?._id || ""} setDefaultSubFormChangeData={setDefaultSubFormChangeData} title={checkKeys[moduleData?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? "" : mainModuleSubFormChangeModal?.related_list_title} />}
            {openCheckBoxPopup && OpenCheckBoxModel && <OpenCheckBoxModel show={!!openCheckBoxPopup} handleClose={() => {
                setOpenCheckBoxModel(null);
                setOpenCheckBoxPopup(null);
            }} submitData={(data) => {
                setValue(Helpers.replaceData(openCheckBoxPopup), data);
            }} isData={Utils.getSubFormFieldToData(Helpers.replaceData(openCheckBoxPopup), storeData?.data) || {}} dueDate={Utils.getSubFormFieldToData("Due_Date", storeData?.data) || ""} />}
            {openParticipantModal && <AddParticipants show={!!openParticipantModal} handleClose={() => {
                setOpenParticipantModal(null);
            }} submitData={(data) => {
                setValue(Helpers.replaceData(openParticipantModal), data);
            }} isData={Utils.getSubFormFieldToData(Helpers.replaceData(openParticipantModal), storeData?.data) || {}} basedSubFormId={subFormId || ""} moduleName={basedModuleStoreData?.name || ""} />}
            {sendInvitationModal && <SendInvitationModal show={sendInvitationModal} handleClose={() => setSendInvitationModal(false)} submitData={(flg) => {
                submit(true, flg);
                setSendInvitationModal(false);
            }} />}
        </>
    );
}

export default CreateAssignment;