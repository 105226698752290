import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    // error: null,
    successStatus: null,
    approvalStatus: null,
    updateStatus: null,
    createStatus: false,
    reload: false,
    createStatus: null,
    selectedData: null,
    allData: null,
    ruleModuleData: null,
    templateData: null,
    uploadStatus: null,
    imageData: null
}

const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        createWorkflowStart: (state) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        createWorkflowSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        createWorkflowFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        getWorkflowStart: (state) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
            state.allData = null;
        },
        getWorkflowSuccess: (state, action) => {
            // state.data = action?.payload?.data;
            state.data = action?.payload?.rules;
            state.allData = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.updateStatus = null;
        },
        getWorkflowFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = null
        },
        getWorkflowByIdStart: (state) => {
            state.isLoading = true;
            state.selectedData = null;
            state.createStatus = null;
            state.updateStatus = null;
        },
        getWorkflowByIdSuccess: (state, action) => {
            // state.data = action?.payload?.data;
            state.data = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.selectedData = action?.payload?.rules;
        },
        getWorkflowByIdFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        getApprovalStart: (state) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        getApprovalSuccess: (state, action) => {
            // state.data = action?.payload?.data;
            state.data = action?.payload?.formModules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getApprovalFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        getApprovalByIdStart: (state) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        getApprovalByIdSuccess: (state, action) => {
            // state.data = action?.payload?.data;
            state.data = action?.payload?.formModules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getApprovalByIdFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updateWorkflowStart: (state, action) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        updateWorkflowSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateWorkflowFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateApprovalStatusStart: (state, action) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        updateApprovalStatusSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.approvalStatus = action?.payload?.success;
        },
        updateApprovalStatusFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.approvalStatus = action?.payload?.success;
        },
        updateRejectStatusStart: (state, action) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
        },
        updateRejectStatusSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.approvalStatus = action?.payload?.success;
        },
        updateRejectStatusFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.approvalStatus = action?.payload?.success;
        },
        removeWorkflowByIdStart: (state, action) => {
            state.isLoading = true;
            state.createStatus = null;
            state.updateStatus = null;
            state.reload = null;
        },
        removeWorkflowByIdSuccess: (state, action) => {
            state.isLoading = false;
            state.reload = true;
            state.message = action?.payload?.message;
            state.successStatus = action?.payload?.status;
        },
        removeWorkflowByIdFailure: (state, action) => {
            state.isLoading = false;
            // state.error = action?.payload;
            state.message = action?.payload?.message;
            state.successStatus = action?.payload?.success;
        },
        getWorkflowModuleStart: (state) => {
            state.isLoading = true;
            state.createStatus = false;
            state.updateStatus = null;
            state.ruleModuleData = null;
        },
        getWorkflowModuleSuccess: (state, action) => {
            // state.data = action?.payload?.data;
            // state.data = action?.payload?.rules;
            state.ruleModuleData = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.updateStatus = null;
        },
        getWorkflowModuleFailure: (state, action) => {
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = null
        },
        getEmailTemplateStart: (state) => {
            state.isLoading = true;
            state.templateData = null;
        },
        getEmailTemplateSuccess: (state, action) => {
            state.templateData = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getEmailTemplateFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        uploadImageStart: (state) => {
            state.isLoading = true;
            state.uploadStatus = null;
            state.imageData = null;
        },
        uploadImageSuccess: (state, action) => {
            state.isLoading = false;
            state.imageData = action?.payload?.s3Url;
            state.uploadStatus = action?.payload?.success;
        },
        uploadImageFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.uploadStatus = action?.payload?.success;
        },
    }
})


export const {
    createWorkflowStart,
    createWorkflowSuccess,
    createWorkflowFailure,
    getWorkflowStart,
    getWorkflowSuccess,
    getWorkflowFailure,
    getWorkflowByIdStart,
    getWorkflowByIdSuccess,
    getWorkflowByIdFailure,
    getApprovalStart,
    getApprovalSuccess,
    getApprovalFailure,
    getApprovalByIdStart,
    getApprovalByIdSuccess,
    getApprovalByIdFailure,
    updateWorkflowStart,
    updateWorkflowSuccess,
    updateWorkflowFailure,
    updateApprovalStatusStart,
    updateApprovalStatusSuccess,
    updateApprovalStatusFailure,
    updateRejectStatusStart,
    updateRejectStatusSuccess,
    updateRejectStatusFailure,
    removeWorkflowByIdStart,
    removeWorkflowByIdSuccess,
    removeWorkflowByIdFailure,
    getWorkflowModuleStart,
    getWorkflowModuleSuccess,
    getWorkflowModuleFailure,
    getEmailTemplateStart,
    getEmailTemplateSuccess,
    getEmailTemplateFailure,
    uploadImageStart,
    uploadImageSuccess,
    uploadImageFailure
} = workflowSlice?.actions

export default workflowSlice?.reducer;