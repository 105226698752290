import { createSlice } from "@reduxjs/toolkit";

const draftMailSlice = createSlice({
    name: 'draft',
    initialState: {
        draftDetails: null
    },
    reducers: {
        getDraftData: (state, action) => {
            // state.draftDetails = action.draftDetails
            state.draftDetails = sessionStorage.getItem('draftDetails')
        },
        setDraftData: (state, action) => {
            state.draftDetails = action.payload
        },
        resetDraftData: (state) => {
            state.draftDetails = null
        }
    }
});

export const {
    getDraftData,
    setDraftData,
    resetDraftData
} = draftMailSlice?.actions;

export default draftMailSlice?.reducer;