import { call, put } from "redux-saga/effects";
import { createWorkflow, getApprovalById, getApprovalList, getEmailTemplateList, getWorkflowById, getWorkflowList, getWorkflowModuleList, removeWorkflowById, updateApprovalStatus, updateRejectStatus, updateWorkflow, uploadWorkflowImage } from "../../../../Services/apiService";
import { createWorkflowFailure, createWorkflowSuccess, getApprovalByIdFailure, getApprovalByIdSuccess, getApprovalFailure, getApprovalSuccess, getEmailTemplateFailure, getEmailTemplateSuccess, getWorkflowByIdFailure, getWorkflowByIdSuccess, getWorkflowFailure, getWorkflowModuleFailure, getWorkflowModuleSuccess, getWorkflowSuccess, removeWorkflowByIdFailure, removeWorkflowByIdSuccess, updateApprovalStatusFailure, updateApprovalStatusSuccess, updateRejectStatusFailure, updateRejectStatusSuccess, updateWorkflowFailure, updateWorkflowSuccess, uploadImageFailure, uploadImageSuccess } from "../../../Slices/workflowSlice";

export function* createWorkflowHandler(actions) {
    try {
        let response = yield call(createWorkflow, actions?.payload);
        yield put(createWorkflowSuccess(response?.data));
    } catch (err) {
        yield put(createWorkflowFailure(err?.response?.data));
    }
}

export function* getWorkflowHandler(actions) {
    try {
        let response = yield call(getWorkflowList, actions?.payload);
        yield put(getWorkflowSuccess(response?.data));
    } catch (err) {
        yield put(getWorkflowFailure(err?.response?.data));
    }
}

export function* getWorkflowByIdHandler(actions) {
    try {
        let response = yield call(getWorkflowById, actions?.payload);
        yield put(getWorkflowByIdSuccess(response?.data));
    } catch (err) {
        yield put(getWorkflowByIdFailure(err?.response?.data));
    }
}

export function* getApprovalHandler(actions) {
    try {
        let response = yield call(getApprovalList, actions?.payload);
        yield put(getApprovalSuccess(response?.data));
    } catch (err) {
        yield put(getApprovalFailure(err?.response?.data));
    }
}

export function* getApprovalByIdHandler(actions) {
    try {
        let response = yield call(getApprovalById, actions?.payload);
        yield put(getApprovalByIdSuccess(response?.data));
    } catch (err) {
        yield put(getApprovalByIdFailure(err?.response?.data));
    }
}

export function* updateWorkflowHandler(actions) {
    try {
        let response = yield call(updateWorkflow, actions?.payload);
        yield put(updateWorkflowSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateWorkflowFailure(errResponse))
    }
}

export function* updateApprovalStatusHandler(actions) {
    try {
        console.log(actions)
        let response = yield call(updateApprovalStatus, actions?.payload);
        yield put(updateApprovalStatusSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateApprovalStatusFailure(errResponse))
    }
}

export function* updateRejectStatusHandler(actions) {
    try {
        let response = yield call(updateRejectStatus, actions?.payload);
        yield put(updateRejectStatusSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateRejectStatusFailure(errResponse))
    }
}

export function* removeWorkflowModuleHandler(actions) {
    try {
        let response = yield call(removeWorkflowById, actions?.payload);
        yield put(removeWorkflowByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(removeWorkflowByIdFailure(errResponse))
    }
}

export function* getWorkflowModuleHandler(actions) {
    try {
        let response = yield call(getWorkflowModuleList, actions?.payload);
        yield put(getWorkflowModuleSuccess(response?.data));
    } catch (err) {
        yield put(getWorkflowModuleFailure(err?.response?.data));
    }
}

export function* getEmailTemplateHandler(actions) {
    try {
        let response = yield call(getEmailTemplateList, actions?.payload);
        yield put(getEmailTemplateSuccess(response?.data));
    } catch (err) {
        yield put(getEmailTemplateFailure(err?.response?.data));
    }
}

export function* uploadImageHandler(actions) {
    try {
        let response = yield call(uploadWorkflowImage, actions?.payload);
        yield put(uploadImageSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(uploadImageFailure(errResponse))
    }
}