import { createSlice } from "@reduxjs/toolkit";

const subFormSlice = createSlice({
    name: 'subForm',
    initialState: {
        isLoading: false,
        isCustomViewLoading: false,
        formsubmodules: [],
        tableColumns: {},
        variants: [],
        totalCount: 0,
        modules: [],
        convertedData: [],
        moduleLogs: [],
        customFormSubView: [],
        workflowRules: false,
        sapStatus: false,
        exit: false,
        reload: false,
        message: null,
        moduleMessage: null,
        status: false,
        isPagination: false,
        createdSubFormCustomView: false,
        updatedSubFormCustomView: false,
        error: null,
        sendMailMessage: null,
        sendMailLoading: null,
        sendMailStatus: null,
        sendScheduleMailMessage: null,
        sendScheduleMailLoading: null,
        sendScheduleMailStatus: null,
        attachFileMessage: null,
        attachFileData: null,
        attachFileStatus: null
    },
    reducers: {
        createSubFormStart: (state, action) => {
            state[action?.payload?.keys ? action?.payload?.keys?.isLoading : "isLoading"] = true;
        },
        createSubFormSuccess: (state, action) => {
            let { keys } = action?.payload;
            state[keys.formsubmodules] = action?.payload?.formsubmodules;
            state[keys.message] = action?.payload?.message;
            state[keys.isLoading] = false;
            state[keys.status] = action?.payload?.status;
            state.reload = false;
            state.exit = true;
        },
        createSubFormFailure: (state, action) => {
            state.error = action?.payload?.message;
            state[action?.payload?.keys ? action?.payload?.keys?.isLoading : "isLoading"] = false;
        },
        getSubFormListStart: (state, action) => {
            state[action?.payload?.keys ? action?.payload?.keys?.isLoading : "isLoading"] = true;
        },
        getSubFormListSuccess: (state, action) => {
            let { keys, isPagination } = action?.payload;
            state[keys.variants] = action?.payload?.variants;
            state[keys.tableColumns] = action?.payload?.tableColumns;
            state[keys.formsubmodules] = action?.payload?.formsubmodules;
            state[keys.totalCount] = action?.payload?.totalCount;
            state[keys.modules] = action?.payload?.modules;
            state[keys.lookupData] = action?.payload?.lookup_data;
            state[keys.workflowRules] = action?.payload?.workflow_rules;
            state[keys.sapStatus] = action?.payload?.sap_status;
            state[keys.message] = action?.payload?.message;
            state[keys.isLoading] = false;
            state[keys.status] = action?.payload?.status;
            state.reload = false;
            state.exit = false;
            state.isPagination = isPagination;
        },
        getSubFormListFailure: (state, action) => {
            state.error = action?.payload?.message;
            state[action?.payload?.keys ? action?.payload?.keys?.isLoading : "isLoading"] = false;
        },
        getSubFormListByIdStart: (state) => {
            state.isLoading = true;
        },
        getSubFormListByIdSuccess: (state, action) => {
            state.formsubmodules = action?.payload?.formsubmodules;
            state.modules = action?.payload?.modules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.status = action?.payload?.status;
            state.reload = false;
            state.exit = false;
        },
        getSubFormListByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        updateSubFormByIdStart: (state, action) => {
            state[action?.payload?.updateKeys?.isLoading] = true;
        },
        updateSubFormByIdSuccess: (state, action) => {
            let updateKeys = action?.payload?.updateKeys;
            if (updateKeys.formsubmodules === "changeOwnerFormsubmodules") {
                state.formsubmodules = state?.formsubmodules?.map(x => x?._id === action?.payload?.updateFormModules?._id ? action?.payload?.updateFormModules : x);
            } else {
                state[updateKeys.formsubmodules] = action?.payload?.formsubmodules;
            }
            state[updateKeys.message] = action?.payload?.message;
            state[updateKeys.exit] = true;
            state[updateKeys.isLoading] = false;
            state[updateKeys.status] = action?.payload?.status;
            state[updateKeys.reload] = false;
        },
        updateSubFormByIdFailure: (state, action) => {
            let updateKeys = action?.payload?.updateKeys;
            state[updateKeys.error] = action?.payload?.message;
            state[updateKeys.isLoading] = false;
        },
        removeSubFormByIdStart: (state, action) => {
            state.isLoading = true;
        },
        removeSubFormByIdSuccess: (state, action) => {
            state.reload = true;
            state.isLoading = false;
            state.status = action?.payload?.status;
        },
        removeSubFormByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        bulkUploadSubFormByIdStart: (state, action) => {
            state.isLoading = true;
        },
        bulkUploadSubFormByIdSuccess: (state, action) => {
            state.reload = true;
            state.isLoading = false;
            state.status = action?.payload?.status;
        },
        bulkUploadSubFormByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        createTableColumnSubFormModuleStart: (state) => {
            state.isLoading = true;
        },
        createTableColumnSubFormModuleSuccess: (state, action) => {
            state.tableColumns = action?.payload?.savedColumns;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createTableColumnSubFormModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        updateTableColumnSubFormModuleStart: (state) => {
            state.isLoading = true;
        },
        updateTableColumnSubFormModuleSuccess: (state, action) => {
            state.tableColumns = action?.payload?.updateColumns;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateTableColumnSubFormModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getSubFormModuleCreatedRecordsStart: (state) => {
            state.isLoading = true;
        },
        getSubFormModuleCreatedRecordsSuccess: (state, action) => {
            let payload = action?.payload;
            state.createdRecords = {
                todayCount: payload?.todayCount,
                oneWeekCount: payload?.oneWeekCount,
                oneMonthCount: payload?.oneMonthCount
            };
            state.message = payload?.message;
            state.isLoading = false;
            state.status = payload?.status;
            state.reload = false;
            state.exit = false;
        },
        getSubFormModuleCreatedRecordsFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getSubFormModuleGraphicalStatusStart: (state) => {
            state.isLoading = true;
        },
        getSubFormModuleGraphicalStatusSuccess: (state, action) => {
            let payload = action?.payload;
            state.graphicalStatus = {
                InApprovalCount: payload?.InApprovalCount,
                SuccessCount: payload?.SuccessCount,
                FailCount: payload?.FailCount
            };
            state.message = payload?.message;
            state.isLoading = false;
            state.status = payload?.status;
            state.reload = false;
            state.exit = false;
        },
        getSubFormModuleGraphicalStatusFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        convertSubformDataStart: (state) => {
            state.isLoading = true;
        },
        convertSubformDataSuccess: (state, action) => {
            state.isLoading = false;
            state.exit = true;
        },
        convertSubformDataFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        convertedSubformDataStart: (state) => {
            state.isLoading = true;
        },
        convertedSubformDataSuccess: (state, action) => {
            state.convertedData = action?.payload?.converted_modules;
            state.isLoading = false;
            state.exit = true;
        },
        convertedSubformDataFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        subFormModuleLogStart: (state) => {
            state.isLoading = true;
        },
        subFormModuleLogSuccess: (state, action) => {
            state.moduleLogs = action?.payload?.module_logs;
            state.moduleMessage = action?.payload?.message;
            state.isLoading = false;
            state.exit = true;
        },
        subFormModuleLogFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        createSubFormCustomViewStart: (state) => {
            state.isCustomViewLoading = true;
        },
        createSubFormCustomViewSuccess: (state, action) => {
            state.createdSubFormCustomView = true;
            state.isCustomViewLoading = false;
            state.exit = true;
        },
        createSubFormCustomViewFailure: (state, action) => {
            state.error = action?.payload;
            state.isCustomViewLoading = false;
        },
        getAllSubFormCustomViewStart: (state) => {
            state.isCustomViewLoading = true;
        },
        getAllSubFormCustomViewSuccess: (state, action) => {
            state.customFormSubView = action?.payload?.custom_formSub_view || [];
            state.isCustomViewLoading = false;
            state.exit = false;
        },
        getAllSubFormCustomViewFailure: (state, action) => {
            state.error = action?.payload;
            state.isCustomViewLoading = false;
        },
        getByIdSubFormCustomViewStart: (state) => {
            state.isCustomViewLoading = true;
        },
        getByIdSubFormCustomViewSuccess: (state, action) => {
            state.isCustomViewLoading = false;
            state.exit = false;
        },
        getByIdSubFormCustomViewFailure: (state, action) => {
            state.error = action?.payload;
            state.isCustomViewLoading = false;
        },
        updateSubFormCustomViewStart: (state) => {
            state.isCustomViewLoading = true;
        },
        updateSubFormCustomViewSuccess: (state, action) => {
            state.updatedSubFormCustomView = true;
            state.isCustomViewLoading = false;
            state.exit = true;
        },
        updateSubFormCustomViewFailure: (state, action) => {
            state.error = action?.payload;
            state.isCustomViewLoading = false;
        },
        resetStateModule: (state) => {
            state.formsubmodules = [];
            state.modules = [];
            state.exit = false;
        },
        resetExit: (state) => {
            state.exit = false;
        },
        destroyModuleLog: (state) => {
            state.moduleLogs = [];
            state.moduleMessage = null;
        },
        destroychangeOwnerExit: (state) => {
            state.changeOwnerExit = false;
        },
        destroyCustomFormSubView: (state) => {
            state.customFormSubView = [];
            state.createdSubFormCustomView = false;
            state.updatedSubFormCustomView = false;
        },
        sendManualMailStart: (state) => {
            state.sendMailLoading = true;
            state.sendMailMessage = null;
            state.sendMailStatus = null;
        },
        sendManualMailSuccess: (state, action) => {
            state.sendMailLoading = false;
            state.sendMailMessage = action?.payload?.message;
            state.sendMailStatus = action?.payload?.success;
        },
        sendManualMailFailure: (state, action) => {
            state.sendMailLoading = false;
            state.sendMailMessage = action?.payload?.success;
            state.sendMailStatus = false;
        },
        sendScheduleMailStart: (state) => {
            state.sendScheduleMailLoading = true;
            state.sendScheduleMailMessage = null;
            state.sendScheduleMailStatus = null;
        },
        sendScheduleMailSuccess: (state, action) => {
            state.sendScheduleMailLoading = false;
            state.sendScheduleMailMessage = action?.payload?.message;
            state.sendScheduleMailStatus = action?.payload?.success;
        },
        sendScheduleMailFailure: (state, action) => {
            state.sendScheduleMailLoading = false;
            state.sendScheduleMailMessage = action?.payload?.success;
            state.sendScheduleMailStatus = false;
        },
        resetSendManualMail: (state) => {
            state.sendMailLoading = null;
            state.sendMailMessage = null;
            state.sendMailStatus = null;
        },
        resetSendScheduleMail: (state) => {
            state.sendScheduleMailLoading = null;
            state.sendScheduleMailMessage = null;
            state.sendScheduleMailStatus = null;
        },
        attachFileStart: (state) => {
            state.attachFileStatus = null;
            state.attachFileData = null;
            state.attachFileMessage = null;
        },
        attachFileSuccess: (state, action) => {
            state.attachFileData = action?.payload?.s3Url;
            state.attachFileStatus = action?.payload?.success;
        },
        attachFileFailure: (state, action) => {
            state.attachFileMessage = action?.payload?.message
            state.attachFileStatus = false;
        },
        resetAttachFile: (state) => {
            state.attachFileStatus = null;
            state.attachFileData = null;
            state.attachFileMessage = null;
        }
    }
});

export const {
    createSubFormStart,
    createSubFormSuccess,
    createSubFormFailure,
    getSubFormListStart,
    getSubFormListSuccess,
    getSubFormListFailure,
    getSubFormListByIdStart,
    getSubFormListByIdSuccess,
    getSubFormListByIdFailure,
    updateSubFormByIdStart,
    updateSubFormByIdSuccess,
    updateSubFormByIdFailure,
    removeSubFormByIdStart,
    removeSubFormByIdSuccess,
    removeSubFormByIdFailure,
    bulkUploadSubFormByIdStart,
    bulkUploadSubFormByIdSuccess,
    bulkUploadSubFormByIdFailure,
    createTableColumnSubFormModuleStart,
    createTableColumnSubFormModuleSuccess,
    createTableColumnSubFormModuleFailure,
    updateTableColumnSubFormModuleStart,
    updateTableColumnSubFormModuleSuccess,
    updateTableColumnSubFormModuleFailure,
    getSubFormModuleCreatedRecordsStart,
    getSubFormModuleCreatedRecordsSuccess,
    getSubFormModuleCreatedRecordsFailure,
    getSubFormModuleGraphicalStatusStart,
    getSubFormModuleGraphicalStatusSuccess,
    getSubFormModuleGraphicalStatusFailure,
    convertSubformDataStart,
    convertSubformDataSuccess,
    convertSubformDataFailure,
    convertedSubformDataStart,
    convertedSubformDataSuccess,
    convertedSubformDataFailure,
    subFormModuleLogStart,
    subFormModuleLogSuccess,
    subFormModuleLogFailure,
    createSubFormCustomViewStart,
    createSubFormCustomViewSuccess,
    createSubFormCustomViewFailure,
    getAllSubFormCustomViewStart,
    getAllSubFormCustomViewSuccess,
    getAllSubFormCustomViewFailure,
    getByIdSubFormCustomViewStart,
    getByIdSubFormCustomViewSuccess,
    getByIdSubFormCustomViewFailure,
    updateSubFormCustomViewStart,
    updateSubFormCustomViewSuccess,
    updateSubFormCustomViewFailure,
    resetStateModule,
    resetExit,
    destroyModuleLog,
    destroychangeOwnerExit,
    destroyCustomFormSubView,
    sendManualMailStart,
    sendManualMailSuccess,
    sendManualMailFailure,
    resetSendManualMail,
    attachFileStart,
    attachFileSuccess,
    attachFileFailure,
    resetAttachFile,
    sendScheduleMailStart,
    sendScheduleMailSuccess,
    sendScheduleMailFailure,
    resetSendScheduleMail
} = subFormSlice?.actions;

export default subFormSlice?.reducer;