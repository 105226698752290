import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSubFormListStart, removeSubFormByIdStart, resetStateModule, getSubFormModuleCreatedRecordsStart, getSubFormModuleGraphicalStatusStart, convertedSubformDataStart, getAllSubFormCustomViewStart } from "../../../Store/Slices/subFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { Utils } from "../../../Utils";
import ImportModal from "../../../Components/ModalPopup/FormModules/ImportModal";
import Loader from "../../../Components/Loader";
import ConfirmationModal from "../../../Components/ModalPopup/ConfirmationModal";
import { Helpers } from "../../../Helpers";
import { HeaderImages } from "../../../Assets";
import ManageColumn from "../../../Components/ModalPopup/FormModules/ManageColumn";
import Charts from "../../../Components/Charts";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import Pagination from "../../../Components/formModules/Pagination";
import PreviewModal from "../../../Components/ModalPopup/FormModules/PreviewModal";
import { createTableColumnSubFormModuleStart, updateTableColumnSubFormModuleStart, destroyModuleLog, destroyCustomFormSubView } from "../../../Store/Slices/subFormSlice";
import { getItem } from "../../../Services/localService";
import PermissionDeniedModal from "../../../Components/PermissionDeniedModal";
import { Collapse } from 'react-bootstrap';
import CreateAssignment from "../../../Components/ModalPopup/FormModules/CreateAssignment";
import dayjs from "dayjs";
import OwnerChange from "../../../Components/ModalPopup/FormModules/OwnerChange";
import EmailComposer from "../../../Components/EmailComposer";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SubModule = () => {
    let currentUserData = getItem('user');
    let basicPermissionData = getItem('modulePermission')?.basic;
    let formsubmodules = useSelector((state) => state?.subForm);
    let { id } = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let [dynamicTableHeader, setDynamicTableHeader] = useState([]);
    let [allSubFormlayoutList, setAllSubFormlayoutList] = useState([]);
    let [subFormlayoutList, setSubFormlayoutList] = useState([]);
    let [openImportModal, setOpenImportModal] = useState(false);
    let [removeModalData, setRemoveModalData] = useState(null);
    let [showManageColumn, setShowManageColumn] = useState(null);
    let [columnDefs, setColumnDefs] = useState([]);
    let gridRef = useRef();
    let [showCharts, setShowCharts] = useState(false);
    let [currentPage, setCurrentPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let [selectedDays, setSelectedDays] = useState("Week");
    let [showFilter, setShowFilter] = useState(false);
    let [showPreviewModalId, setShowPreviewModalId] = useState(null);
    let [permissionDenied, setPermissionDenied] = useState(false);
    let [open, setOpen] = useState(localStorage.getItem("isChartOpen") === "true");
    let [selectedSubformId, setSeletedSubFormId] = useState(null);
    let formList = useSelector((state) => state?.forms);
    let [taskModuleData, setTaskModuleData] = useState(null);
    let [selectedFilterName, setSelectedFilterName] = useState(null);
    let [isTeam, setIsTeam] = useState(true);
    let [convertedData, setConvertedData] = useState([]);
    let [ownerChangeModal, setOwnerChangeModal] = useState(null);
    let [allCustomFormSubView, setAllcustomFormSubView] = useState([]);
    let [selectedCustomFormSubView, setSelectedCustomFormSubView] = useState(null);
    let [sendEmail, setSendEmail] = useState(false);
    let [selectedRowData, setSelectedRowData] = useState();

    useEffect(() => {
        if (id?.length > 0) {
            dispatch(getAllSubFormCustomViewStart(id));
        }
        return () => {
            dispatch(destroyCustomFormSubView());
            setAllcustomFormSubView([]);
            setSelectedCustomFormSubView(null);
        }
    }, [id]);

    useEffect(() => {
        if (selectedCustomFormSubView) {
            if (formsubmodules?.isPagination) {
                dispatch(getSubFormListStart({ id }));
            }
        }
    }, [selectedCustomFormSubView]);

    useEffect(() => {
        if (formsubmodules?.customFormSubView?.length > 0) {
            setAllcustomFormSubView(formsubmodules?.customFormSubView);
        }
    }, [formsubmodules?.customFormSubView]);

    let getFormsubmodule = (formId) => {
        if (selectedFilterName === `Converted ${formsubmodules?.modules?.name}`) {
            return formsubmodules?.convertedData?.find(x => x?._id === formId);
        }
        return formsubmodules?.formsubmodules?.find(x => x?._id === formId);
    }

    useEffect(() => {
        if (!formsubmodules?.graphicalStatus && !formsubmodules?.isLoading) {
            setOpen(false);
        }
    }, [formsubmodules?.graphicalStatus]);

    useEffect(() => {
        if (formsubmodules?.modules?.name?.replaceAll(' ', '').toLowerCase() == 'articlemaster') {
            setShowCharts(true);
        } else {
            setShowCharts(false);
        }
        let subFormDatas = selectedCustomFormSubView ? Utils.filteredDataGet(formsubmodules.formsubmodules, selectedCustomFormSubView) : formsubmodules.formsubmodules;
        if (subFormDatas?.length > 0 && id) {
            let processedData = subFormDatas.map((item) => {
                return {
                    _id: item?._id,
                    ...[...item.data].reduce((acc, curr) => ({
                        ...acc,
                        ...curr.section_data
                    }), {}),
                    User_Name: item?.userId?.firstName ? (item?.userId?.firstName + ' ' + item?.userId?.lastName) : '_',
                    SAP_Status: (item.sapResponse?.sapStatus || '_'),
                    SAP_Message: (item.sapResponse?.sapMsg || '_'),
                    ...(formsubmodules?.workflowRules ? { Status: item.status || '_' } : {})
                }
            });
            setSubFormlayoutList(processedData);
            setAllSubFormlayoutList(processedData);
        } else {
            setSubFormlayoutList([]);
            setAllSubFormlayoutList([]);
        }
        if (formsubmodules?.tableColumns?.data?.length > 0) {
            let tableColumns = formsubmodules.tableColumns.data || [];
            let staticColumns = [
                { label: "User Name", show: true, static: true },
                { label: "SAP Status", show: true, static: true },
                { label: "SAP Message", show: true, static: true }
            ];
            let staticStatusColumn = { label: "Status", show: true, static: true };
            let doesLabelExist = (label) => tableColumns.some(col => col.label === label);
            if (formsubmodules?.workflowRules && !doesLabelExist(staticStatusColumn.label)) {
                tableColumns = [staticStatusColumn, ...tableColumns];
            }
            if (formsubmodules?.sapStatus) {
                let filteredStaticColumns = staticColumns.filter(col => !doesLabelExist(col.label));
                tableColumns = [...filteredStaticColumns, ...tableColumns];
            }
            setDynamicTableHeader(tableColumns);
            setColumnData(tableColumns);

        } else {
            if (formsubmodules?.modules?._id && id) {
                let moduleStoreData = formsubmodules.modules?.fields;
                if (moduleStoreData) {
                    let fieldLabels = [
                        ...(formsubmodules?.sapStatus
                            ? [
                                { label: "User Name", show: true, static: true },
                                { label: "SAP Status", show: true, static: true },
                                { label: "SAP Message", show: true, static: true }
                            ]
                            : []
                        ),
                        ...(formsubmodules?.workflowRules ? [{ label: "Status", show: true, static: true }] : [])
                    ];
                    moduleStoreData?.sections.forEach(section => {
                        Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
                            value?.forEach(item => {
                                let label = item?.extra_fields?.field_label;
                                if (label && ([...Utils.stringInputTypes, ...Utils.numberInputTypes, ...Utils.dateInputTypes]?.includes(item?.input_type))) {
                                    fieldLabels.push({ label, show: true, static: false });
                                }
                            });
                        });
                    });
                    setDynamicTableHeader(fieldLabels);
                    setColumnData(fieldLabels);
                }
            } else {
                setDynamicTableHeader([]);
            }
        }
        setSelectedFilterName(`Open ${formsubmodules?.modules?.name}`);
    }, [formsubmodules?.modules, formsubmodules?.tableColumns, formsubmodules?.formsubmodules, selectedCustomFormSubView]);

    useEffect(() => {
        if (formsubmodules?.modules?._id?.length > 0 && formsubmodules?.modules?.name === "Article Master") {
            dispatch(getSubFormModuleCreatedRecordsStart(id));
            dispatch(getSubFormModuleGraphicalStatusStart({
                moduleId: id,
                status: selectedDays
            }));
        }
    }, [formsubmodules?.modules?._id]);

    let createAssignmentData = (id, name) => {
        if (formList?.navbarList?.length > 0) {
            setTaskModuleData(formList?.navbarList?.find(x => x?.name === name));
            setSeletedSubFormId(id);
        } else {
            setTaskModuleData(null);
            setSeletedSubFormId(null);
        }
    }

    const setColumnData = (data) => {
        let formatData = (inputType, value) => {
            if (Array.isArray(value)) {
                return value?.map((item) => item?.name).join(", ");
            } else if (typeof value === "object" && value !== null) {
                return value?.name?.trim() || '';
            } else if (value && inputType?.input_type === "date" || value && inputType?.input_type === "datetime-local") {
                return dayjs(value).format("MMM D, YYYY hh:mm A");
            } else {
                return (typeof value === "string" ? value?.trim() : value) || "_";
            }
        }
        let columns = data?.filter(x => x.show)?.map((x) => ({
            headerName: x.label,
            field: Helpers.replaceData(x.label),
            valueGetter: (params) => {
                let headerName = params?.colDef?.headerName;
                let inputType = Utils.getParticularsModuleFields(formsubmodules?.modules?.fields?.sections, headerName);
                let value = params?.data[Helpers.replaceData(x.label)] || "";
                return formatData(inputType, value);
            },
            cellRenderer: (params) => {
                let headerName = params?.colDef?.headerName;
                let inputType = Utils.getParticularsModuleFields(formsubmodules?.modules?.fields?.sections, headerName);
                let value = params?.value === undefined ? "" : params?.value;
                return formatData(inputType, value);
            },
            filter: true
        })) || [];

        columns.unshift({
            headerCheckboxSelection: true,
            checkboxSelection: true,
            filter: false,
            sortable: false,
            width: 50
        })

        columns.unshift({
            width: 50,
            resizable: false,
            suppressMovable: true,
            filter: false,
            sortable: false,
            cellRenderer: (params) => {
                let rowId = params.data?._id;
                let dropdownId = `dropdownMenu-${rowId}`;
                return (
                    <div className="nested-dropdown dropdown">
                        <i className="fa fa-ellipsis-h cursor-pointer"
                            id={dropdownId}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        ></i>
                        <ul className="dropdown-menu end-unset p-0" aria-labelledby={dropdownId}>
                            <li>
                                <a className="dropdown-item"
                                    onClick={() => setShowPreviewModalId(rowId)}>
                                    Display
                                </a>
                            </li>
                            {
                                !params?.data?.converted && (
                                    <>
                                        <li>
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    dispatch(resetStateModule());
                                                    navigate(
                                                        Utils.getNavigationUrl(`form-module/sub-form/update/${id}/${rowId}`)
                                                    );
                                                }}
                                            >
                                                Edit
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => { setSendEmail(true); setSelectedRowData(params.data) }}>Send Email</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => createAssignmentData(rowId, "Tasks")}>Create Task</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => {
                                                setOwnerChangeModal(rowId)
                                            }}>Change Owner</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => navigate(Utils.getNavigationUrl(`form-module/convert/${formsubmodules?.modules?._id}/${rowId}`))}>Convert</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item"
                                                onClick={() =>
                                                    !basicPermissionData?.some(
                                                        (x) => x?.id === id && x.activities.delete === true
                                                    )
                                                        ? setPermissionDenied(true)
                                                        : setRemoveModalData({
                                                            _id: rowId,
                                                        })
                                                }
                                            >
                                                Delete
                                            </a>
                                        </li>
                                        <li className="nested-dropdown dropdown-submenu">
                                            <a className="dropdown-item dropdown-toggle">More...</a>
                                            <ul className="dropdown-menu">
                                                <li className="nested-dropdown dropdown-submenu p-0">
                                                    <a className="dropdown-item dropdown-toggle">Create Call</a>
                                                    <ul className="dropdown-menu">
                                                        <li className="p-0">
                                                            <a className="dropdown-item" onClick={() => createAssignmentData(rowId, "Calls")}>Schedule a call</a>
                                                        </li>
                                                        <li className="p-0">
                                                            <a className="dropdown-item" onClick={() => createAssignmentData(rowId, "Calls")}>Log a call</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="p-0">
                                                    <a className="dropdown-item" onClick={() => createAssignmentData(rowId, "Meetings")}>Create Meeting</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )
                            }
                        </ul>
                    </div>

                );
            },
            cellStyle: { "overflow": 'visible' }
        });


        setColumnDefs(columns);
    }

    useEffect(() => {
        if (formsubmodules?.reload && id?.length > 0) {
            dispatch(getSubFormListStart({ page: currentPage, count: pageSize, id }));
        }
    }, [formsubmodules?.reload]);

    const removeData = (subModuleId) => {
        dispatch(removeSubFormByIdStart(subModuleId));
    }

    const clickExportData = () => {
        let fileName = Helpers.replaceData(formsubmodules?.modules?.name);
        Utils.exportData([dynamicTableHeader?.map(x => x?.label)], fileName);
    }

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            event.target.value,
        );
    }

    useEffect(() => {
        if (id?.length > 0) {
            dispatch(getSubFormListStart({ page: currentPage, count: pageSize, id }));
        }
    }, [id, currentPage, pageSize]);

    let handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    let handleNextPage = () => {
        let totalPages = Math.ceil(formsubmodules?.totalCount / pageSize);
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    let handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    let changeDay = (val) => {
        if (val === selectedDays) return;
        dispatch(getSubFormModuleGraphicalStatusStart({
            moduleId: id,
            status: val
        }));
        setSelectedDays(val);
    }

    useEffect(() => {
        if (!allSubFormlayoutList?.length && formsubmodules?.modules?.name !== "Leads") return;
        let pickListData = Utils.getModuleFields(formsubmodules?.modules?.fields?.sections, ["select"]);
        let moduleName = formsubmodules?.modules?.name;
        let filteredList = [];
        switch (selectedFilterName) {
            case `Open ${moduleName}`:
                filteredList = isTeam
                    ? allSubFormlayoutList
                    : allSubFormlayoutList?.filter(x =>
                        Object.entries(x).some(([key, value]) =>
                            key.includes("Owner") && value?._id === currentUserData?._id
                        )
                    );
                break;
            case "Junk Leads":
            case "Not Qualified Leads":
                let nameIs = selectedFilterName === "Junk Leads" ? "junk" : "not_qualified";
                filteredList = allSubFormlayoutList?.filter(x => {
                    let pick = pickListData?.moduleFields?.find(
                        y => y?.fields?.extra_fields?.field_label === "Lead Status"
                    );
                    let leadStatusMatches = pick?.fields?.extra_fields?.lead_status?.[
                        pick?.fields?.extra_fields?.options?.indexOf(x?.Lead_Status)
                    ] === nameIs;
                    if (isTeam) return leadStatusMatches;
                    let ownerMatches = Object.entries(x || {}).some(
                        ([key, value]) => key.includes("Owner") && value?._id === currentUserData?._id
                    );
                    return leadStatusMatches && ownerMatches;
                });
                break;
            case `Converted ${moduleName}`:
                filteredList = isTeam
                    ? convertedData
                    : convertedData?.filter(x =>
                        Object.entries(x).some(([key, value]) =>
                            key.includes("Owner") && value?._id === currentUserData?._id
                        )
                    );
                break;

            default:
                break;
        }
        setSubFormlayoutList(filteredList);
    }, [allSubFormlayoutList, selectedFilterName, isTeam, convertedData]);


    useEffect(() => {
        if (selectedFilterName === `Converted ${formsubmodules?.modules?.name}`) {
            if (!formsubmodules?.convertedData?.length) {
                dispatch(convertedSubformDataStart(id));
            }
        }
    }, [selectedFilterName]);

    useEffect(() => {
        if (formsubmodules?.convertedData?.length > 0) {
            let processedData = formsubmodules?.convertedData.map((item) => {
                return {
                    _id: item?._id,
                    ...item.data.reduce((acc, curr) => ({
                        ...acc,
                        ...curr.section_data
                    }), {}),
                    User_Name: item?.userId?.firstName ? (item?.userId?.firstName + ' ' + item?.userId?.lastName) : '_',
                    SAP_Status: (item.sapResponse?.sapStatus || '_'),
                    SAP_Message: (item.sapResponse?.sapMsg || '_'),
                    Status: item.status || '_',
                    converted: true
                }
            });
            setConvertedData(processedData);
        }
    }, [formsubmodules?.convertedData]);

    return (
        <>
            <Loader isVisible={formsubmodules?.isLoading && !selectedSubformId || formsubmodules?.isCustomViewLoading} />
            <div className="container-fluid">
                <div className="row">
                    <Collapse in={open}>
                        <div className="col-12  mt-3">
                            <div className="card text-end">
                                <div className="card-body p-0" id="collapseExample">
                                    <div className="row">
                                        {
                                            (showCharts && formsubmodules?.graphicalStatus) && <>
                                                <div className="col-md-4">
                                                    <div className="card rounded-4 shadow-sm">
                                                        <div className="card-body p-0">
                                                            <div className="card-title h6 fw-semibold d-flex align-items-center">
                                                                <span>Artical Master Status</span>
                                                                <div className="ms-auto">
                                                                    <div className="btn-group btn-group-sm">
                                                                        <button type="button" className="btn flat-btn dropdown-toggle rounded lh-sm" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <span>{selectedDays}</span> <i className="fa-solid fa-ellipsis"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu min-w-auto">
                                                                            <li><a className="dropdown-item cursor-pointer" onClick={() => changeDay("Day")}>Day</a></li>
                                                                            <li><a className="dropdown-item cursor-pointer" onClick={() => changeDay("Week")}>Week</a></li>
                                                                            <li><a className="dropdown-item cursor-pointer" onClick={() => changeDay("Month")}>Month</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="article-donut-chart">
                                                                <Charts type='donut' series={[(formsubmodules?.graphicalStatus?.InApprovalCount || 0), (formsubmodules?.graphicalStatus?.SuccessCount || 0), (formsubmodules?.graphicalStatus?.FailCount || 0)]}
                                                                    options={{
                                                                        chart: {
                                                                            type: 'donut',
                                                                            height: '100%'
                                                                        },
                                                                        dataLabels: {
                                                                            enabled: false
                                                                        },

                                                                        responsive: [{
                                                                            breakpoint: 768,
                                                                            options: {
                                                                                chart: {
                                                                                    width: 300,
                                                                                    height: 200
                                                                                },
                                                                                legend: {
                                                                                    position: 'bottom',
                                                                                }
                                                                            }
                                                                        }],
                                                                        legend: {
                                                                            markers: {
                                                                                fillColors: ["#A4B0BE", "#29409d", "#29409d80"]
                                                                            }
                                                                        },
                                                                        labels: ["In Approval", "Success", "Error"],
                                                                        fill: {
                                                                            colors: ["#A4B0BE", "#29409d", "#29409d80"]
                                                                        }
                                                                    }} />
                                                                <div id="html-dist"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card rounded-4 shadow-sm h-100">
                                                        <div className="card-body p-0">
                                                            <div className="card-title h6 fw-semibold">Created Records</div>
                                                            <div className="progress-chart h-75 d-flex flex-column justify-content-around">
                                                                <div className="daily">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">Daily</span>
                                                                        <span className="count">{formsubmodules?.createdRecords?.todayCount}</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "75%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="weekly">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">Weekly</span>
                                                                        <span className="count">{formsubmodules?.createdRecords?.oneWeekCount}</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "50%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="monthly">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">Monthly</span>
                                                                        <span className="count">{formsubmodules?.createdRecords?.oneMonthCount}</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "62%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card rounded-4 shadow-sm h-100">
                                                        <div className="card-body p-0">
                                                            <div className="card-title h6 fw-semibold">Material Type</div>
                                                            <div className="progress-chart h-75 d-flex flex-column justify-content-around">
                                                                <div className="daily">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">SW</span>
                                                                        <span className="count">456</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "75%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="weekly">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">RF</span>
                                                                        <span className="count">456448</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "50%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="monthly">
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="label">RAW</span>
                                                                        <span className="count">200000</span>
                                                                    </div>
                                                                    <div className="progress" style={{ height: "6px" }}>
                                                                        <div className="progress-bar rounded-2" role="progressbar" aria-label="Example 1px high"
                                                                            style={{ width: "62%", backgroundColor: "#29409d" }}
                                                                            aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <div className="col-md-12 pt-3">
                        <div className="card rounded-4 border-0 shadow-sm sub-form-module">
                            <div className="card-body p-0">
                                <div className="col-md-12 setup-actions d-flex pb-2 pt-0">
                                    <div className="me-3">
                                        <button className="btn btn-primary rounded-pill btn-digy-dark" onClick={() => navigate(-1)}>
                                            <i className="fa fa-arrow-left me-2"></i> Back
                                        </button>
                                    </div>
                                    <div className="left d-flex align-items-start">
                                        <div className="search-box border rounded-pill">
                                            <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                            <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged} />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="dropdown">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-primary me-2px rounded-start btn-digy-dark"> {selectedCustomFormSubView?.custom_view_name || selectedFilterName} </button>
                                                <button type="button" className="btn btn-primary btn-digy-dark dropdown-toggle dropdown-toggle-split rounded-end" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-caret-down"></i>
                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                </button>

                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    {allCustomFormSubView?.length > 0 && (
                                                        <>
                                                            <li><strong>Created By Me</strong></li>
                                                            {
                                                                allCustomFormSubView?.map((x, i) => (
                                                                    <li key={i}><a className="dropdown-item" onClick={() => setSelectedCustomFormSubView(x)}>{x?.custom_view_name}</a></li>
                                                                ))
                                                            }
                                                        </>
                                                    )}
                                                    <li><strong>Public Views</strong></li>
                                                    <li><a className="dropdown-item" onClick={() => {
                                                        setSelectedFilterName(`Open ${formsubmodules?.modules?.name}`);
                                                        setSelectedCustomFormSubView(null);
                                                    }}>Open {formsubmodules?.modules?.name}</a></li>
                                                    {formsubmodules?.modules?.name === "Leads" && (
                                                        <>
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                setSelectedFilterName(`Converted ${formsubmodules?.modules?.name}`);
                                                                setSelectedCustomFormSubView(null);
                                                            }}>Converted {formsubmodules?.modules?.name}</a></li>
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                setSelectedFilterName(`Junk Leads`);
                                                                setSelectedCustomFormSubView(null);
                                                            }}>Junk {formsubmodules?.modules?.name}</a></li>
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                setSelectedFilterName(`Not Qualified Leads`);
                                                                setSelectedCustomFormSubView(null);
                                                            }}>Not Qualified {formsubmodules?.modules?.name}</a></li>
                                                        </>
                                                    )}
                                                    <li><a className="dropdown-item link-primary" onClick={() => navigate(Utils.getNavigationUrl(`form-module/custom-view/${id}`))}>New Custom View</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="d-flex align-items-center">
                                            <span className="me-2">Self</span>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" checked={isTeam} onChange={(e) => setIsTeam(e.target.checked)} />
                                            </div>
                                            <span className="me-2">Team</span>
                                        </div>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-primary me-2px rounded-start btn-digy-dark" onClick={() => navigate(Utils.getNavigationUrl(`form-module/sub-form/create/${id}`))}
                                                disabled={!basicPermissionData?.some(x => x?.id === id && x.activities.create === true)}>
                                                <i className="fa fa-plus me-2"></i> Create {formsubmodules?.modules?.name}
                                            </button>
                                            <button type="button" className="btn btn-primary btn-digy-dark dropdown-toggle dropdown-toggle-split rounded-end" data-bs-toggle="dropdown" aria-expanded="false"
                                                disabled={!basicPermissionData?.some(x => x?.id === id && x.activities.create === true)}>
                                                <i className="fa fa-caret-down"></i>
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                            <button type="button" className={`btn px-2 rounded-2 ${showFilter ? 'btn-digy-dark' : 'btn-digy-dark-outline'}`} onClick={() => setShowFilter(!showFilter)}>
                                                <i className="fa fa-filter"></i>
                                            </button>
                                            {(showCharts && formsubmodules?.graphicalStatus) && (
                                                <button type="button" className={`btn px-2 rounded-2 ${open ? 'btn-digy-dark' : 'btn-digy-dark-outline'}`} onClick={() => {
                                                    setOpen(!open);
                                                    localStorage.setItem("isChartOpen", !open);
                                                }} aria-controls="collapseExample">
                                                    <i className="fa fa-pie-chart"></i>
                                                </button>
                                            )}
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item cursor-pointer" onClick={() => setOpenImportModal(true)}><i className="fa fa-file-import me-2"></i> Import</a></li>
                                                <hr className="dropdown-divider" />
                                                <li><a className="dropdown-item cursor-pointer" onClick={clickExportData}><i className="fa-solid fa-file-export me-2"></i> Export</a></li>
                                            </ul>

                                            <button className="nav-link p-0 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={HeaderImages.settingIcon} alt="setting-icon" width="25" height="25" />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <li><a className="dropdown-item cursor-pointer" onClick={() => setShowManageColumn(true)}>Manage Column</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    {/* height: showCharts && formsubmodules?.graphicalStatus ? 'calc(100vh - 45vh)' : 'calc(100vh - 17vh)' */}
                                    {columnDefs?.length > 0 && <div className={`ag-theme-quartz ${showCharts && formsubmodules?.graphicalStatus ? 'with-charts' : 'without-charts'}`} style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={subFormlayoutList || []}
                                            columnDefs={columnDefs}
                                            defaultColDef={{
                                                filter: showFilter ? 'agTextColumnFilter' : showFilter,
                                                floatingFilter: showFilter,
                                            }}
                                            onCellClicked={(e) => {
                                                let columnId = e?.data?._id;
                                                let clickedColumn = e.colDef.field;
                                                if (!clickedColumn || !columnId) return;
                                                setShowPreviewModalId(columnId);
                                            }}
                                            rowSelection="multiple"
                                            pagination={!formsubmodules?.isPagination}
                                            rowHeight={40}
                                            floatingFiltersHeight={40}
                                            headerHeight={40}
                                        />
                                        {formsubmodules?.isPagination && (
                                            <Pagination
                                                currentPage={currentPage}
                                                pageSize={pageSize}
                                                totalCount={formsubmodules?.totalCount}
                                                onPageSizeChange={handlePageSizeChange}
                                                onPrevPage={handlePrevPage}
                                                onNextPage={handleNextPage}
                                            />
                                        )}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openImportModal && <ImportModal show={openImportModal} handleClose={() => setOpenImportModal(false)} moduleId={id} />}
            {removeModalData && <ConfirmationModal data={removeModalData} show={true} handleClose={() => setRemoveModalData(null)} handleSuccess={(subModuleId) => {
                removeData(subModuleId);
                setRemoveModalData(null);
            }} />}
            {showManageColumn && <ManageColumn show={showManageColumn} handleClose={() => setShowManageColumn(null)} getValue={dynamicTableHeader} setValue={setDynamicTableHeader} data={{ moduleId: id, tableColumnId: formsubmodules?.tableColumns?._id }} createApi={createTableColumnSubFormModuleStart} updateApi={updateTableColumnSubFormModuleStart} />}
            {showPreviewModalId !== null && <PreviewModal show={showPreviewModalId !== null} preview={true} moduleStoreData={formsubmodules?.modules?.fields} handleClose={() => {
                setShowPreviewModalId(null)
                dispatch(destroyModuleLog());
            }
            } storeData={getFormsubmodule(showPreviewModalId) || {}} subFormId={showPreviewModalId} moduleId={id} />}
            {permissionDenied && <PermissionDeniedModal />}
            {(selectedSubformId && taskModuleData) && <CreateAssignment show={true} handleClose={() => {
                setSeletedSubFormId(null);
                setTaskModuleData(null);
            }} moduleData={taskModuleData} subFormId={selectedSubformId} basedModuleId={id} basedSubFormData={getFormsubmodule(selectedSubformId) || {}} basedModuleStoreData={formsubmodules?.modules} />}
            {ownerChangeModal && <OwnerChange show={!!ownerChangeModal} handleClose={() => {
                setOwnerChangeModal(null);
            }} basedModuleStoreData={formsubmodules?.modules} basedSubFormData={getFormsubmodule(ownerChangeModal) || {}} />}
            {sendEmail &&
                <EmailComposer
                    sendEmail={sendEmail}
                    setSendEmail={setSendEmail}
                    id={id}
                    selectedRowData={selectedRowData}
                    moduleName={formsubmodules?.modules?.name}
                />
            }
        </>
    );
};

export default SubModule;
