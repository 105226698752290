import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import registerReducer from './registerSlice';
import dashboardReducer from './dashboardSlice';
import formReducer from './formSlice';
import userReducer from './userSlice';
import subFormReducer from './subFormSlice';
import workflowReducer from './workflowSlice';
import webhookReducer from './webhookSlice';
import personalSettingsReducer from './personalSettingsSlice';
import userSettingsReducer from './userSettingsSlice';
import variantConfigReducer from './variantConfigSlice';
import organizationReducer from './organizationSlice';
import fiscalYearReducer from './fiscalYearSlice';
import businessHoursReducer from './businessHoursSlice';
import shiftHoursReducer from './shiftHoursSlice';
import currencyReducer from './currencySlice';
import subCurrencyReducer from './subCurrencySlice';
import dashboardComponentsReducer from './dashboardComponentsSlice';
import profileReducer from './profileSlice';
import roleReducer from './roleSlice';
import loginHistoryReducer from './loginHistorySlice';
import auditLogReducer from './auditLogSlice';
import draftReducer from './draftMailSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    register: registerReducer,
    dashboard: dashboardReducer,
    forms: formReducer,
    user: userReducer,
    subForm: subFormReducer,
    workflow: workflowReducer,
    webhooks: webhookReducer,
    personalsettings: personalSettingsReducer,
    usersettings: userSettingsReducer,
    variantConfig: variantConfigReducer,
    org: organizationReducer,
    fiscalyear: fiscalYearReducer,
    businesshours: businessHoursReducer,
    shifthours: shiftHoursReducer,
    currency: currencyReducer,
    subcurrency: subCurrencyReducer,
    dashboardComponents: dashboardComponentsReducer,
    profile: profileReducer,
    role: roleReducer,
    loginhistory: loginHistoryReducer,
    auditlog: auditLogReducer,
    draft: draftReducer
});

export default rootReducer;