import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const DraftHeader = ({ draftDetails, currentDraftIndex, closeModal }) => {
    const [selectedDraftIndex, setSelectedDraftIndex] = useState(0);

    useEffect(() => {
        currentDraftIndex(selectedDraftIndex)
    }, [selectedDraftIndex])

    return (
        <>
            {
                draftDetails?.map((draft, index) => (
                    <Offcanvas.Header
                        // closeButton
                        className="pb-2 position-relative cursor-pointer"
                        onClick={() => setSelectedDraftIndex(index)}
                        style={{
                            backgroundColor: selectedDraftIndex === index && '#338cf0',
                            color: selectedDraftIndex === index ? '#FFF' : '#333'
                        }}
                    >
                        <Offcanvas.Title
                            className="fw-semibold"
                            style={{ fontSize: '14px' }}
                        >
                            {draft?.subject || 'New Message'}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                ))
            }
            <Offcanvas.Header>
                <div>
                    <IoClose
                        style={{
                            color: '#7f7f7f',
                            fontSize: "1.5rem",
                            cursor: "pointer"
                        }}
                        title="Close"
                        onClick={() => closeModal(false)}
                    />
                </div>
            </Offcanvas.Header>
        </>
    )
}
export default DraftHeader;