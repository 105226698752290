import React, { useEffect, useState } from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { FaFile, FaPaperclip, FaRegClock } from "react-icons/fa6";
import { RiDeleteBin6Fill } from "react-icons/ri";
import CreatableSelect from 'react-select/creatable'
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../Services/localService";
import { DraftWarningModal, ScheduleModal, SelectTemplateModal } from "./modals";
import { setDraftData } from "../../Store/Slices/draftMailSlice";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import { attachFile } from "../../Services/apiService";
import { resetSendManualMail, resetSendScheduleMail, sendManualMailStart, sendScheduleMailStart } from "../../Store/Slices/subFormSlice";
import { toast } from "react-toastify";
import { getEmailTemplateStart } from "../../Store/Slices/workflowSlice";

const DraftBody = ({ currentDraftData, currentDraftIndex, closeModal }) => {
    const dispatch = useDispatch();
    const user = getItem('user');
    let id = currentDraftData?.id;
    let selectedRowData = currentDraftData?.selectedRowData;

    const components = {
        DropdownIndicator: null,
    };

    const createOption = (label) => ({
        label,
        value: label,
    });

    const { quill, quillRef, Quill } = useQuill({
        modules: { BlotFormatter: {} }
    });

    if (Quill && !quill) {
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    let subformResponse = useSelector((state) => state?.subForm);
    let workflowList = useSelector((state) => state?.workflow);
    let [showDraftWarningModal, setShowDraftWarningModal] = useState(false);
    let [showTemplateModal, setShowTemplateModal] = useState(false);
    let [selectedTemplate, setSelectedTemplate] = useState();

    let [inputValue, setInputValue] = useState('');
    let [value, setValue] = useState([]);
    let [inputCcValue, setInputCcValue] = useState('');
    let [ccValue, setCcValue] = useState([]);
    let [inputBccValue, setInputBccValue] = useState('');
    let [bccValue, setBccValue] = useState([]);
    let [subject, setSubject] = useState();
    let [showCc, setShowCc] = useState(true);
    let [showBcc, setShowBcc] = useState(false);
    let [editorContent, setEditorContent] = useState();
    let [files, setFiles] = useState([]);
    let [showLoader, setShowLoader] = useState(false);
    let [showScheduleModal, setShowScheduleModal] = useState(false);
    let [scheduleAt, setScheduleAt] = useState('');
    let [scheduleType, setScheduleType] = useState('');
    let [scheduleDay, setScheduleDay] = useState('');
    let [isSchedule, setIsSchedule] = useState(false);

    useEffect(() => {
        dispatch(getEmailTemplateStart(id));
        setValue(currentDraftData?.to);
        setCcValue(currentDraftData?.cc);
        setBccValue(currentDraftData?.bcc);
        setSubject(currentDraftData?.subject ? currentDraftData?.subject : '');
        // debugger
        // if (currentDraftData?.content) {
        //     if (quill) {
        //         quill.clipboard.dangerouslyPasteHTML(currentDraftData?.content);
        //     }
        // }
        // else {
        //     if (quill) {
        //         quill.setContents([]);
        //     }
        // }
        if (currentDraftData?.bcc?.length) {
            setShowBcc(true)
        } else {
            setShowBcc(false)
        }
    }, [currentDraftData])

    useEffect(() => {
        if (currentDraftData?.content) {
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(currentDraftData?.content);
            }
        }
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                setEditorContent(quill.root.innerHTML)
            });
        }
    }, [quill, Quill])

    useEffect(() => {
        if (subformResponse?.sendMailStatus) {
            toast.success(subformResponse?.sendMailMessage, {
                autoClose: 2000,
                onClose: () => {
                    setShowLoader(false);
                    removeCurrentDraftData();
                    dispatch(resetSendManualMail());
                    closeModal(false);
                }
            })
        }
        if (subformResponse?.sendMailStatus === false) {
            toast.error(subformResponse?.sendMailMessage, {
                autoClose: 2000,
                onClose: () => {
                    setShowLoader(false)
                    dispatch(resetSendManualMail());
                }
            })
        }
        setShowLoader(false)
    }, [subformResponse?.sendMailStatus])

    useEffect(() => {
        if (subformResponse?.sendScheduleMailStatus) {
            toast.success(subformResponse?.sendScheduleMailMessage, {
                autoClose: 2000,
                onClose: () => {
                    setShowLoader(false);
                    removeCurrentDraftData();
                    dispatch(resetSendScheduleMail());
                    closeModal(false);
                }
            })
        }
        if (subformResponse?.sendScheduleMailStatus === false) {
            toast.error(subformResponse?.sendScheduleMailMessage, {
                autoClose: 2000,
                onClose: () => {
                    setShowLoader(false);
                    dispatch(resetSendScheduleMail());
                    // setSendEmail(false);
                }
            })
        }
    }, [subformResponse?.sendScheduleMailStatus])

    useEffect(() => {
        if (selectedTemplate?.templateData?.subject) {
            setSubject(selectedTemplate?.templateData?.subject)
        }
        if (selectedTemplate?.templateData?.content) {
            let updatedContent = getDynamicContent(selectedTemplate?.templateData?.content)
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(updatedContent);
            }
        }
    }, [selectedTemplate])

    // useEffect(() => {
    //     // let sessionData = JSON.parse(sessionStorage.getItem('draftDetails')) || []
    //     // sessionData[currentDraftIndex].to = value
    //     // sessionData[currentDraftIndex].cc = ccValue
    //     // sessionData[currentDraftIndex].bcc = bccValue
    //     // sessionData[currentDraftIndex].subject = subject
    //     // sessionData[currentDraftIndex].content = editorContent
    //     // sessionStorage.setItem('draftDetails', JSON.stringify(sessionData))
    //     // dispatch(setDraftData(JSON.stringify(sessionData)))
    // }, [editorContent, value, ccValue, bccValue, subject])

    const getDynamicContent = (content) => {
        const regex = /\$\{([^}]+)\}/g;
        const matches = [];
        let match;
        while ((match = regex.exec(content)) !== null) {
            matches.push(match[1]);
            let mt = match[1];
            let selectedField = mt.split('.')[1].replaceAll(' ', '_')
            let field = selectedField
            let type = typeof (selectedRowData[selectedField])
            let _field;
            if (type === 'object') {
                _field = selectedRowData[selectedField]['name']
            } else if (type === 'string') {
                _field = selectedRowData[selectedField]
            }
            let _mt = '${' + mt + '}'
            content = content.replaceAll(_mt, _field)
        }
        setEditorContent(content)
        return content
    }

    const removeCurrentDraftData = () => {
        let sessionData = JSON.parse(sessionStorage.getItem('draftDetails')) || []
        if (sessionData.length > 0) {
            let updatedArray = sessionData.filter((_, i) => i !== currentDraftIndex);
            sessionStorage.setItem("draftDetails", JSON.stringify(updatedArray));
            let allDrafts = sessionStorage.getItem('draftDetails')
            dispatch(setDraftData(allDrafts))
        }
    }

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleKeyDownCc = (event) => {
        if (!inputCcValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setCcValue((prev) => [...prev, createOption(inputCcValue)]);
                setInputCcValue('');
                event.preventDefault();
        }
    }

    const handleKeyDownBcc = (event) => {
        if (!inputBccValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setBccValue((prev) => [...prev, createOption(inputBccValue)]);
                setInputBccValue('');
                event.preventDefault();
        }
    }

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await attachFile({ payload: formData });
            return response?.data?.s3Url;
        } catch (error) {
            console.error("Error uploading file:", file.name, error);
            throw error;
        }
    };

    const convertContentToHtml = async () => {
        let htmlHeader = `<html lang="en">
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Email Template</title>
    
            <style media="all" type="text/css">
                body{
                    margin: 0;
                }
            </style>
        </head>
    <body>`
        let htmlFooter = ` </body>
    </html>`;
        editorContent = editorContent ? editorContent : "no content"
        let obj = htmlHeader + editorContent + htmlFooter;
        return obj;
    }

    const removeFile = async (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const fileSizeConverter = (fileSizeInBytes) => {
        let fileSizeWithUnit = "";
        if (fileSizeInBytes < 1024) {
            fileSizeWithUnit = `${fileSizeInBytes} Bytes`;
        } else if (fileSizeInBytes < 1024 * 1024) {
            const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
            fileSizeWithUnit = `${fileSizeInKB} KB`;
        } else {
            const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
            fileSizeWithUnit = `${fileSizeInMB} MB`;
        }
        return fileSizeWithUnit;
    }

    const handleSchedule = async (data) => {
        setScheduleType(data?.type)
        if (data.type === 'schedule') {
            setShowScheduleModal(false);
            setScheduleAt(data?.schedule_at)
            setScheduleDay(data?.schedule_day)
            setIsSchedule(true);
        }
        if (data.type === 'schedule_close') {
            setShowScheduleModal(false);
            setShowLoader(true)
            let payload = {
                from: user?.email.replaceAll('@', '.'),
                to: value.map((val) => val.value),
                cc: ccValue.map((val) => val.value),
                bcc: bccValue.map((val) => val.value),
                reply_to: user?.email,
                subject: subject,
                content: await convertContentToHtml()
            }
            if (files.length > 0) {
                const uploadResponses = [];
                for (const file of files) {
                    try {
                        const response = await uploadFile(file);
                        uploadResponses.push({ filename: file.name, path: response });
                    } catch (error) {
                        console.error("Skipping file due to upload error:", file.name);
                    }
                }
                payload['attachments'] = uploadResponses
            }
            payload['schedule_at'] = data.schedule_at
            payload['formModuleId'] = id
            dispatch(sendScheduleMailStart(payload))
        }
    }

    const sendMail = async () => {
        setShowLoader(false);
        let payload = {
            from: user?.email.replaceAll('@', '.'),
            to: value.map((val) => val.value),
            cc: ccValue.map((val) => val.value),
            bcc: bccValue.map((val) => val.value),
            reply_to: user?.email,
            subject: subject,
            content: await convertContentToHtml()
        }
        if (files.length > 0) {
            const uploadResponses = [];
            for (const file of files) {
                try {
                    const response = await uploadFile(file);
                    uploadResponses.push({ filename: file.name, path: response });
                } catch (error) {
                    console.error("Skipping file due to upload error:", file.name);
                }
            }
            payload['attachments'] = uploadResponses
        }
        dispatch(sendManualMailStart(payload));
    }

    return (
        <>
            <Offcanvas.Body className="vh-100" style={{ overflowY: 'hidden' }}>
                <Loader isVisible={showLoader || subformResponse?.sendMailLoading} isModal={true} overall={false} />
                <div className="row">
                    <div className="col-12 setup-actions d-flex pb-2 pt-0">
                        <div className="left d-flex justify-content-center align-items-center">
                            {user?.firstName + ' ' + user?.lastName + ' <' + user?.email + '>'}
                        </div>
                        <div className="ms-auto">
                            <span className="me-3">
                                Draft saved at {currentDraftData?.draftAt}
                                <RiDeleteBin6Fill className="mb-1 ms-1 cursor-pointer"
                                    onClick={() => { setShowDraftWarningModal(true) }}
                                />
                            </span>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary rounded-pill btn-blue"
                                    onClick={() => setShowTemplateModal(true)}
                                >
                                    <span>Insert Template</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0" />
                    <div className="col-12 setup-actions d-flex pb-2 pt-2">
                        <Row className="m-0 w-100">
                            <div className="col-1">To</div>
                            <div className="col-9">
                                <CreatableSelect
                                    className="mail-multi-select"
                                    components={components}
                                    inputValue={inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onChange={(newValue) => setValue(newValue)}
                                    onInputChange={(newValue) => setInputValue(newValue)}
                                    onKeyDown={handleKeyDown}
                                    placeholder=""
                                    value={value}
                                />
                            </div>
                            <div className="col-2">
                                <span className={`fw-semibold text-primary me-3 cursor-pointer ${showCc && 'd-none'}`}
                                    onClick={() => setShowCc(true)}>CC</span>
                                <span className={`fw-semibold text-primary me-3 cursor-pointer ${showBcc && 'd-none'}`}
                                    onClick={() => setShowBcc(true)}>BCC</span>
                            </div>
                        </Row>
                    </div>
                    <hr className="m-0" />
                    {showCc &&
                        <>
                            <div className="col-12 setup-actions d-flex pb-2 pt-2">
                                <Row className="m-0 w-100">
                                    <div className="col-1">Cc</div>
                                    <div className="col-9">
                                        <CreatableSelect
                                            className="mail-multi-select"
                                            components={components}
                                            inputValue={inputCcValue}
                                            isClearable
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setCcValue(newValue)}
                                            onInputChange={(newValue) => setInputCcValue(newValue)}
                                            onKeyDown={handleKeyDownCc}
                                            placeholder=""
                                            value={ccValue}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <span className="fw-semibold text-primary me-3 cursor-pointer"
                                            onClick={() => setShowCc(false)}>X</span>
                                    </div>
                                </Row>
                            </div>
                            <hr className="m-0" />
                        </>
                    }
                    {showBcc &&
                        <>
                            <div className="col-12 setup-actions d-flex pb-2 pt-2">
                                <Row className="m-0 w-100">
                                    <div className="col-1">Bcc</div>
                                    <div className="col-9">
                                        <CreatableSelect
                                            className="mail-multi-select"
                                            components={components}
                                            inputValue={inputBccValue}
                                            isClearable
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setBccValue(newValue)}
                                            onInputChange={(newValue) => setInputBccValue(newValue)}
                                            onKeyDown={handleKeyDownBcc}
                                            placeholder=""
                                            value={bccValue}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <span className="fw-semibold text-primary me-3 cursor-pointer"
                                            onClick={() => setShowBcc(false)}>X</span>
                                    </div>
                                </Row>
                            </div>
                            <hr className="m-0" />
                        </>
                    }
                    <div className="col-12 setup-actions d-flex pb-2 pt-2">
                        <Row className="m-0 w-100">
                            <div className="col-1">Subject</div>
                            <div className="col-9">
                                <input type="text" className="form-control mail-field"
                                    onChange={(e) => setSubject(e.target.value)}
                                    name="subject"
                                    value={subject}
                                />
                            </div>
                        </Row>
                    </div>
                    <hr className="m-0" />
                    <div className="col-12 setup-actions d-flex pb-2 pt-2"
                        style={{ position: 'relative' }}>
                        <div className="row m-0 w-100">
                            <div className="col-12">
                                <div ref={quillRef} />
                            </div>
                            <div className={`collapse ${files?.length === 0 && 'd-none'}`} id="collapseExample"
                                style={{
                                    position: 'absolute',
                                    bottom: '-35px',
                                    left: '0',
                                    margin: '0',
                                    padding: '0',
                                    width: '100%',
                                    boxShadow: '0 -0.16rem 0.16rem rgba(0, 0, 0, 0.175)'
                                }}>
                                <div class="card card-body"
                                    style={{
                                        padding: '10px',
                                        height: '100px',
                                        maxHeight: '100px',
                                        overflowX: 'hidden',
                                        overflowY: 'scroll'
                                    }}>
                                    {files?.length > 0 && (
                                        <div className="row">
                                            {files?.map((file, index) => (
                                                <div className="col-4">
                                                    <div class="card card-body mt-1"
                                                        style={{
                                                            padding: '10px',
                                                            height: '70px',
                                                            overflow: 'hidden scroll'
                                                        }}>
                                                        <div className="row">
                                                            <div className="col-2 d-flex justify-content-center align-items-center p-0">
                                                                <FaFile className="me-1" style={{ fontSize: '20px' }} />
                                                            </div>
                                                            <div className="col-8">
                                                                <span className="w-100">
                                                                    {file.name}
                                                                </span>
                                                                <div>{fileSizeConverter(file.size)}</div>
                                                            </div>
                                                            <div className="col-2 d-flex justify-content-center align-items-center p-0">
                                                                <i className="fas fa-trash text-danger float-end"
                                                                    onClick={() => removeFile(index)}>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <Col lg={12} className="text-start ps-0 form-footer position-absolute bottom-0 w-100 bg-white border-top ps-4 py-3 d-flex
                                                position-fixed">
                        <button
                            className="btn btn-outline-primary btn-blue me-3 p-2"
                            type="button"
                            onClick={() => document.getElementById("selectImage").click()}
                        >
                            <input
                                id='selectImage'
                                hidden
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.zip,.jpg,.jpeg,.png,.gif"
                                multiple
                                onChange={handleFileChange}
                            />
                            <FaPaperclip />
                        </button>
                        {files?.length > 0 &&
                            <button
                                className="btn btn-outline-primary btn-blue me-3 p-2"
                                type="button"
                                data-bs-toggle="collapse" href="#collapseExample"
                            >
                                {files?.length} Attachents
                            </button>
                        }
                        {scheduleDay &&
                            < div className="me-3 d-flex align-items-center justify-content-center"
                                style={{ border: 'none' }}>
                                <input className="form-check-input" type="checkbox" id="agreeToc" required="" value=""
                                    onChange={(e) => setIsSchedule(e?.target?.checked)}
                                    name="isSchedule"
                                    checked={isSchedule}
                                />
                                <label className="form-check-label ms-2">Schedule {scheduleDay}</label>
                            </div>
                        }
                        <button
                            className="btn btn-outline-primary btn-blue me-3 p-2"
                            type="button"
                            onClick={() => setShowScheduleModal(true)}
                        >
                            <FaRegClock /> <span className="ms-2">Schedule</span>
                        </button>
                        <button
                            className="btn btn-primary rounded-pill btn-blue"
                            type="button"
                            onClick={() => sendMail()}
                        >
                            Send
                        </button>
                    </Col>
                </div>
            </Offcanvas.Body>
            <ScheduleModal
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
                handleSchedule={handleSchedule}
            />
            <DraftWarningModal
                showDraftWarningModal={showDraftWarningModal}
                setShowDraftWarningModal={setShowDraftWarningModal}
                currentDraftIndex={currentDraftIndex}
                removeCurrentDraftData={removeCurrentDraftData}
            />
            <SelectTemplateModal
                showTemplateModal={showTemplateModal}
                setShowTemplateModal={setShowTemplateModal}
                workflowList={workflowList}
                setSelectedTemplate={setSelectedTemplate}
            />
        </>
    )
}
export default DraftBody;