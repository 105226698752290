import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Toast from "../Toast";
import DraftHeader from "./draftHeader";
import DraftBody from "./draftBody";

const Drafts = ({ sendEmail, setSendEmail, draftDetails }) => {
    let [currentDraftIndex, setCurrentDraftIndex] = useState(0);

    return (
        <React.Fragment>
            <Toast />
            <Offcanvas placement='end'
                show={sendEmail}
                onHide={() => { setSendEmail(false); }}
                className="email-composer timeline-offcanvas"
                backdrop={true}
                scroll={false}
            >
                <div className="d-flex">
                    <DraftHeader
                        draftDetails={draftDetails}
                        currentDraftIndex={(i) => setCurrentDraftIndex(i)}
                        closeModal={(v) => setSendEmail(v)}
                    />
                </div>
                <hr className="m-0" />
                <DraftBody
                    currentDraftData={draftDetails[currentDraftIndex]}
                    currentDraftIndex={currentDraftIndex}
                    closeModal={(v) => setSendEmail(v)}
                />
            </Offcanvas >
        </React.Fragment >
    )
}
export default Drafts;