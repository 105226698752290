import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    userTypeData: null,
    userData: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    },
    updateStatus: null,
    allUserData: null,
    deleteStatus: null,
    passwordUpdateStatus: null
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUserListStart: (state) => {
            state.isLoading = true;
        },
        getUserListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getUserListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        getUserByIdStart: (state) => {
            state.isLoading = true;
        },
        getUserByIdSuccess: (state, action) => {
            state.data = action?.payload?.user;
            state.userData = action?.payload?.user;
            state.message = action?.payload?.message;
            state.successStatus = action?.payload?.success;
            state.isLoading = false
        },
        getUserByIdFailure: (state, action) => {
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getAllUserListStart: (state) => {
            state.isLoading = true;
        },
        getAllUserListSuccess: (state, action) => {
            state.totalCount = action?.payload?.totalCount;
            state.data = action?.payload?.usersDetails;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getAllUserListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        updateUserStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        updateUserSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            // state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateUserFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        deleteUserStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.deleteStatus = null;
        },
        deleteUserSuccess: (state, action) => {
            state.message = action?.payload?.message;
            // state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            // state.reload = action?.payload?.success;
            state.deleteStatus = action?.payload?.success;
        },
        deleteUserFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            // state.deleteStatus = action?.payload?.success;
            // state.reload = action?.payload?.success;
        },
        uploadUserProfileStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        uploadUserProfileSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            // state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        uploadUserProfileFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        getAllUserDataStart: (state) => {
            state.isLoading = true;
            state.allUserData = null;
        },
        getAllUserDataSuccess: (state, action) => {
            state.totalCount = action?.payload?.totalCount;
            state.data = action?.payload?.usersDetails;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.allUserData = action?.payload?.usersDetails;
        },
        getAllUserDataFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        getAllUserTypeListStart: (state) => {
            state.isLoading = true;
        },
        getAllUserTypeListSuccess: (state, action) => {
            // state.totalCount = action?.payload?.totalCount;
            state.userTypeData = action?.payload?.usersDetails;
            // state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getAllUserTypeListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        changePasswordStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.passwordUpdateStatus = null;
        },
        changePasswordSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.passwordUpdateStatus = action?.payload?.success;
        },
        changePasswordFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload
            state.passwordUpdateStatus = false;
        },
        userResetState: (state) => {
            state.message = null;
            state.reload = null;
            state.successStatus = null;
            state.updateStatus = null;
            state.passwordUpdateStatus = null;
        }
    }
})

export const {
    getUserListStart,
    getUserListSuccess,
    getUserListFailure,
    getAllUserListStart,
    getAllUserListSuccess,
    getAllUserListFailure,
    getUserByIdStart,
    getUserByIdSuccess,
    getUserByIdFailure,
    updateUserStart,
    updateUserSuccess,
    updateUserFailure,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailure,
    uploadUserProfileStart,
    uploadUserProfileSuccess,
    uploadUserProfileFailure,
    getAllUserDataStart,
    getAllUserDataSuccess,
    getAllUserDataFailure,
    getAllUserTypeListStart,
    getAllUserTypeListSuccess,
    getAllUserTypeListFailure,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,
    userResetState
} = userSlice?.actions

export default userSlice?.reducer;